.message-list-secure-block {
  margin-right: -11px;
}

.message-list-return-block {
  margin-right: -35px;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  border: 1px solid rgb(235, 237, 248);
}

.message-list-secure-list {
  overflow: hidden;
  margin-top: 0em;
  padding: 0;
  max-height: 41rem;
}

.message-list-secure-list:hover {
  overflow-y: auto;
}

.message-list-return-list {
  max-height: 26.5rem;
  margin-bottom: 0;
}

.message-list-active-list-item {
  background-color: #ffffff;
  border: 1px solid #ebedf8;
  list-style: none;
  height: 100%;
  padding: 1rem 0 1rem 1.5rem;
  max-width: 100%;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  color: #1a173b;
  border-right-color: #ffffff;
}

.message-list-inactive-list-item {
  font-family: Roboto;
  font-size: 15px;
  line-height: 18px;
  height: 100%;
  padding: 1rem 0 1rem 1.5rem;
  max-width: 100%;
  cursor: pointer;
  list-style: none;
  color: #0077ff;
}

.message-list-unread {
  font-weight: 500;
}

.message-list-item-subject {
  max-width: 95%;
  font-size: 15px;
}

.message-list-item-date {
  font-size: 12px;
  color: #67727c;
}

.message-list-new-messages {
  border-radius: 5px;
  cursor: pointer;
  background-color: #e1e9f4;
  text-align: center;
}
