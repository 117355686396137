
@media screen and (min-width:1301px) {
  #btnContinueIRSMessage {
    padding: 0 4rem;
    margin-left: 25rem;
  }
}

@media screen and (max-width:1300px) {
  #btnContinueIRSMessage {
    padding: 0 4rem;
    margin-left: 15rem;
  }
}

@media screen and (max-width:1000px) {
  #btnContinueIRSMessage {
    padding: 0 4rem;
    margin-left: 10rem;
  }
}

@media screen and (max-width:900px) {
  #btnContinueIRSMessage {
    padding: 0 4rem;
    margin-left: 0rem;
  }
}

