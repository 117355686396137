.tableRowBoxShadow {
  background-color: #f3f4f9;
  box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.08);
}

.previewTableLabel {
  height: 24px;
  width: auto;
  color: #1a173b;
  font-size: 21px;
  font-weight: normal;
  letter-spacing: 0.14px;
  line-height: 1em;
  padding-bottom: 2em;
}

.tableLabel {
  font-family: Roboto;
  font-size: 21px;
  color: #1a173b;
  height: 24px;
  letter-spacing: 0.14px;
}

.tableColumnLabel {
  color: #354052;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 14px;
  padding-top: 2em;
  border: none !important;
}

.tableClientDataLink {
  color: #0077ff;
  font-size: 15px;
  line-height: 20px;
}

.tableTypicalRowData {
  color: #67727c;
  font-size: 15px;
  letter-spacing: 0.1px;
  line-height: 20px;
  vertical-align: middle !important;
}

.tablePreparerRowData {
  color: #67727c;
  font-size: 15px;
  letter-spacing: 0.1px;
  line-height: 20px;
  vertical-align: middle;
  display: table-cell;
}

.tableRowDetailHeader {
  height: 11px;
  width: 109px;
  color: #1a173b;
  font-size: 10px;
  letter-spacing: 1.43px;
  line-height: 11px;
}

.tableRowDetailLine1 {
  height: 18px;
  width: 46px;
  color: #00c44f;
  font-family: Roboto;
  font-size: 15px;
  letter-spacing: 0.1px;
  line-height: 18px;
}

.tableRowDetailLine2 {
  height: 18px;
  width: 55px;
  color: #8a96a0;
  font-family: Roboto;
  font-size: 15px;
  letter-spacing: 0.1px;
  line-height: 18px;
}

.tableRow {
  transition: 0.5s;
}

.tableRow:hover {
  background-color: #f3f4f9;
}

.tableVerticalMenu {
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-out;
  position: relative;
  left: 0.5em;
  top: 0em;
}

.tableVerticalMenuVisible {
  cursor: pointer;
  transition: all 0.5s ease-out;
  visibility: visible;
  position: relative;
  left: 0.5em;
  top: 0em;
}

.tableRow:hover .tableVerticalMenu {
  visibility: visible;
  opacity: 1;
}

.tableRowBoxShadow .tableVerticalMenu {
  visibility: visible;
  opacity: 1;
}

.tableExpandArrow {
  cursor: pointer;
  margin-bottom: 0.5em;
  transform: rotate(180deg);
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-out;
  position: relative;
  left: 1.5em;
  top: 0.2em;
}

.tableExpandArrowVisible {
  cursor: pointer;
  margin-bottom: 0.5em;
  transform: rotate(180deg);
  transition: all 0.5s ease-out;
  position: relative;
  left: 1.5em;
  top: 0.2em;
}

.tableExpandArrowDown {
  cursor: pointer;
  margin-bottom: 0.5em;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s;
  left: 1.5em;
  top: 0.2em;
  -webkit-filter: saturate(1.5) drop-shadow(0 0 0 #0077ff);
  filter: saturate(1.5) drop-shadow(0 0 0 #0077ff);
}

.tableExpandArrowUp {
  cursor: pointer;
  margin-bottom: 0.5em;
  transform: rotate(-180deg);
  transition: 0.5s;
  position: relative;
  transition: all 0.5s;
  left: 1.5em;
  top: 0.2em;
  -webkit-filter: saturate(1.5) drop-shadow(0 0 0 #0077ff);
  filter: saturate(1.5) drop-shadow(0 0 0 #0077ff);
}

#iconBtnExpandStateList {
  cursor: pointer;
}

.tableRow:hover .tableExpandArrow {
  visibility: visible;
  opacity: 1;
}

.arrowClicked {
  transform: rotate(360deg);
  /*for animation*/
  cursor: pointer;
  visibility: visible;
  position: relative;
  left: 1.5em;
}

.verticalAlign {
  vertical-align: middle !important;
}

.filterDropDown {
  height: 35px;
  border: 1px solid #ebedf8;
  border-radius: 4px;
  background-color: #f3f9ff;
  font-size: 14px;
  font-family: Roboto;
  letter-spacing: 1px;
  margin-right: 5px;
}

.columnDropdown {
  font-size: 12px;
  font-family: Roboto;
  letter-spacing: 1px;
  height: 14px;
  color: #1a173b;
  border: 0;
}

.rowHidden {
  border-top: none !important;
  padding: 0em !important;
}

.rowShown {
  border-top: none !important;
  padding: 1em 0em 1em 0em !important;
}

.tableActionsButton {
  min-height: 35px !important;
  max-height: 35px !important;
  min-width: 24px !important;
  max-width: 24px !important;
  padding: 0px 0px 0px 0px !important;
  border: 1px solid #0077ff !important;
}

.widgetFilter {
  display: inline-block;
  background: #0077ff;
  border-radius: 4px;
  padding: 5px;
  font-weight: 400;
  font-size: 15px;
  margin: 5px;
}

.widgetFilterTitle {
  color: white;
}

.resetWidgetFilterBtn {
  color: white;
  cursor: pointer;
}

.statusIcon {
  float: left;
}

.inputDefault {
  font-family: Roboto;
  font-size: 15px;
  letter-spacing: 1px;
  height: 35px;
  color: #354052;
  border-radius: 4px;
  border-color: 1px solid #ebedf8;
  background-color: #f3f9ff;
  margin-left: 5px;
  width: 150px;
  padding-left: 3px;
  margin-right: 5px;
}

.inputError {
  font-family: Roboto;
  letter-spacing: 1px;
  height: 35px;
  color: #354052;
  border-radius: 4px;
  border: 1px solid #f0a6a9;
  background-color: #ffdadc;
  margin-left: 5px;
  width: 150px;
}

.inputError:focus {
  outline: none;
}

.inputFocus {
  font-family: Roboto;
  letter-spacing: 1px;
  height: 2.19rem;
  color: #354052;
  border-radius: 4px;
  border-color: 1px solid #aacef8;
  background-color: '#FFFFFF';
  margin-left: 5px;
  width: 150px;
  padding-left: 3px;
}

.filterButtonHidden {
  width: auto;
  margin-left: 5px;
  visibility: hidden;
}

.filterButtonShown {
  width: auto;
  margin-left: 5px;
  visibility: visible;
}

.datePickerContainer {
  display: inline-block;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.hiddenBorder {
  border-style: hidden;
}

.noTopBorder {
  border-top: none;
}

.noBottomBorder {
  border-bottom: none;
}

.btnCustomizeTaxReturns {
  margin-right: 80px;
}

.btnSection {
  border-top: none !important;
  padding-left: 2em !important;
}

.noTextDecoration {
  text-decoration: none !important;
}

.capitalizeText {
  text-transform: capitalize;
}

.noPointerEvents {
  pointer-events: none;
}

.btnViewMorePreviewTable {
  margin-right: 70px;
  width: 200px;
}

.centerText {
  text-align: center;
}

.noReturns {
  margin-left: 30px;
  min-height: 300px;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.btnSendAllPreviewTable {
  margin-right: 70px;
}

.searchResults {
  overflow: auto;
}
