.send-signature-paper {
  width: 40em;
  padding: 0;
  position: relative;
  margin: auto;
}

.send-signature-modal-container {
  font-family: Roboto;
  font-size: 15px;
}

.send-signature-image {
  width: 25px;
  height: 25px;
}

.send-signature-title {
  padding-left: 0.5rem;
  vertical-align: text-top;
  font-size: 15px;
}

.send-signature-content {
  margin-top: 2.5rem;
  margin-left: 2.5rem;
}

.send-signature-content-title {
  height: 24px;
  width: 359px;
  margin-bottom: 1.5rem;
  color: #1a173b;
  font-family: Roboto;
  font-size: 21px;
  letter-spacing: 0.14px;
  line-height: 24px;
}

.send-signature-signee-title {
  height: 16px;
  width: 101px;
  color: #354052;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
}

.send-signature-signee {
  padding-top: 0.5rem;
}

.send-signature-modal-button-block {
  text-align: right;
  padding-top: 2em;
  padding-right: 2.5em;
  padding-bottom: 2em;
}

.send-signature-contact-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-top: 3rem;
}

.send-signature-preparer-email {
  color: #354052;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
}

.send-signature-label {
  display: inline-flex;
}
