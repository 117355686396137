button {
  cursor: pointer;
}

.hidden {
  display: none;
}

* {
  font-family: 'Roboto', 'Open Sans', sans-serif;
}

.scroll-y {
  height: 100vh;
  overflow-y: auto;
}

/*webkit-scrollbar only works for chrome, safari, and opera.*/
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(105, 103, 103, 0.87);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #969696;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.sidebarMenuItem {
  color: #1880e7;
  font-family: 'Roboto';
  font-size: 13px;
  line-height: 18px;
}

.sidebarMenuHeader {
  color: #1a173b;
  font-family: 'Roboto';
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.14px;
  padding: 0px;
}

/* https://css-tricks.com/snippets/css/css-triangle/ */
.arrow-down {
  cursor: pointer;
  margin-bottom: 0.2em;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.spinner {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #ffff40;
  background: -moz-linear-gradient(left, #ffff40 10%, rgba(255, 255, 64, 0) 42%);
  background: -webkit-linear-gradient(left, #ffff40 10%, rgba(255, 255, 64, 0) 42%);
  background: -o-linear-gradient(left, #ffff40 10%, rgba(255, 255, 64, 0) 42%);
  background: -ms-linear-gradient(left, #ffff40 10%, rgba(255, 255, 64, 0) 42%);
  background: linear-gradient(to right, #ffff40 10%, rgba(255, 255, 64, 0) 42%);
  position: fixed;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  z-index: 7;
}
.spinner:before {
  width: 50%;
  height: 50%;
  background: #ffff40;
  border-radius: 100% 0 0 0;
  position: fixed;
  top: 0;
  left: 0;
  content: '';
  z-index: 7;
}
.spinner:after {
  background: #fafbfc;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 7;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.ReactCrop__image {
  max-height: 100% !important;
}

.introjs-tooltipbuttons {
  width: 40px;
  color: rgb(5, 13, 22);
  text-align: center;
  margin-right: 5px;
  border-radius: 0px;
}

.introjs-tooltiptext {
  color: rgb(5, 13, 22);
}

.introjs-button.introjs-skipbutton {
  width: 40px;
  color: rgb(5, 13, 22);
  text-align: center;
  margin-right: 5px;
  border-radius: 0px;
  border: 2px solid rgb(122, 126, 129);
}

.introjs-button.introjs-nextbutton {
  width: 40px;
  color: rgb(5, 13, 22);
  text-align: center;
  border-radius: 0px;
  border: 2px solid rgb(122, 126, 129);
}

.introjs-button.introjs-skipbutton:hover {
  box-sizing: content-box;
  border-radius: 0px;
  background-color: transparent;
}

.introjs-button.introjs-nextbutton:hover {
  width: 40px;
  margin-right: 5px;
  text-align: center;
  color: rgb(5, 13, 22);
  border-radius: 0px;
  background-color: transparent;
}

.introjs-button.introjs-prevbutton {
  width: 40px;
  margin-right: 5px;
  text-align: center;
  border-radius: 0px;
  border: 2px solid rgb(122, 126, 129);
}

.introjs-button.introjs-prevbutton:hover {
  width: 40px;
  margin-right: 5px;
  text-align: center;
  color: rgb(5, 13, 22);
  border-radius: 0px;
  background-color: transparent;
}

.introjs-button.introjs-prevbutton.introjs-disabled:hover {
  width: 40px;
  margin-right: 5px;
  text-align: center;
  border-radius: 0px;
}

.introjs-button.introjs-skipbutton:hover {
  width: 40px;
  margin-right: 5px;
  text-align: center;
  color: rgb(5, 13, 22);
  border-radius: 0px;
}

.verticallyCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal-header-bar {
  color: white;
  background-color: #444a59;
  height: 70px;
  padding-top: 1.5rem;
  padding-right: 26px;
  padding-left: 30px;
}

.content-control-scroll {
  height: calc(100vh - 12em);
}

.page-content-scroll {
  height: calc(100vh - 4em);
  overflow-y: auto;
}

.formPaper {
  height: 497px;
  width: 570px;
  border-radius: 4px;
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.btnNewCustomerCustomers {
  text-transform: capitalize;
  padding: 8px;
}

.darkText {
  color: black;
}

.textAlignJustify {
  text-align: justify;
}
