.titleBar {
  z-index: 4;
  /* Important, titlebar should appear on top of everything */
  position: fixed;
  background-color: white;
  width: 100%;
  min-width: 1200px;
  margin-bottom: 0;
}

.logoImg {
  height: 50px;
  width: auto;
  padding-top: 1em;
  padding-left: 1em;
}

.logoImgAppBar {
  max-height: 30px;
  max-width: 185px;
  height: auto;
  width: auto;
  margin-top: 10px;
  padding-left: 0em;
}

.logoImgAppBarNoMargin {
  max-height: 30px;
  max-width: 185px;
  width: auto;
  height: auto;
  margin-top: 0em;
  padding-left: 0em;
  cursor: pointer;
}

.menuIconImg {
  height: 30px;
  width: auto;
  padding-top: 10px;
  padding-left: 20px;
  padding-bottom: 0px;
  cursor: pointer;
}

/* bottom horizontal bar that separates titlebar from body */

.titleSep {
  /* border: none;
  margin-top: 0.9em;
  height: 1px;
  background-color: #333; */
  box-sizing: border-box;
  margin-top: 0.9em;
  height: 2px;
  width: 100%;
  border: 1px solid #ebedf8;
}

.tab {
  /* InVision */
  height: 20px;
  width: 67px;
  color: #1880e7;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 20px;
  cursor: pointer;
  margin-right: 60px;
  padding-bottom: 20px;
}

.tab-focus {
  color: #1a1738;
  width: 90px;
  height: 24px;
  border-bottom: 2.2px solid currentColor;
  text-decoration: none;
}

.tab-focus-training {
  color: #1a1738;
  width: 90px;
  height: 15px;
  padding-bottom: 16px;
  border-bottom: 2.2px solid currentColor;
  text-decoration: none;
}

.tab-blur {
  color: #637280;
}

/* https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting */

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.training-mode {
  padding-left: 1.5em;
  margin-bottom: 0;
  font-family: 'Roboto';
  font-weight: 500;
  letter-spacing: 0.08px;
  font-size: 13px;
  height: 20px;
  background-color: #ffcc11;
  color: black;
}

.leftUserActions {
  position: absolute;
  left: 13em;
  top: 1.3em;
  display: inline;
}

.leftUserActionsNew {
  position: absolute;
  left: 15em;
  top: 1.3em;
  display: inline;
}

.leftUserActionsNewTraining {
  position: absolute;
  left: 15em;
  top: 1.5em;
  display: inline;
}

/* non-tab components of the title bar */

.userActions {
  position: absolute;
  right: 1em;
  top: 1.3em;
  display: inline;
}

.userActionsAvatar {
  position: absolute;
  right: 1em;
  top: 0.85em;
  display: inline;
  margin-left: auto;
  display: inline-flex;
}

.userActionsTraining {
  position: absolute;
  right: 1em;
  top: 1.5em;
  display: inline-flex;
}

.searchIcon {
  cursor: pointer;
  margin-right: 0px;
}

.helpIcon,
.copyIcon {
  margin-right: 8px;
  height: 25px;
  width: 25px;
  align-self: center;
}

.helpIcon {
  cursor: help;
}

.label {
  height: 20px;
  opacity: 0.75;
  color: #637280;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 20px;
  margin-left: 0px;
  margin-right: 36px;
}

.labelselect {
  height: 20px;
  opacity: 0.75;
  color: #637280;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 20px;
  margin-left: 0px;
  margin-right: 36px;
}

.account {
  margin-left: 0em;
  margin-right: 0em;
  cursor: pointer;
}

.account-title-bar {
  margin-left: 0em;
  margin-right: 0em;
  margin-top: 1em;
  cursor: pointer;
}

/* .arrow-down-title{
  border-top: 5px solid #637280;
} */

.arrow-down {
  margin-left: 0;
  cursor: pointer;
  margin-bottom: 0.2em;
  margin-right: 1em;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #637280;
}

.arrow-left {
  cursor: pointer;
  margin-top: 0.2em;
  width: 0;
  height: 0;
  border-bottom: 6px solid transparent;
  border-right: 6px solid black;
  border-top: 6px solid transparent;
  position: relative;
  top: 0.6em;
  right: 0.5em;
}

.login-button {
  background-color: #0077ff;
  font-family: 'Avenir Next';
  font-size: 15px;
  letter-spacing: 0.1px;
}

.loggedin-user {
  display: inline;
  cursor: pointer;
}

.backButton {
  cursor: pointer;
  background-color: #ebedf8;
  padding-top: 2em;
  padding-bottom: 2em;
  padding-left: 2em;
  padding-right: 2em;
  font-size: 15px;
  font-weight: 500;
  width: 70px;
}
