.logo-container {
  float: none;
  margin-left: 1rem;
}

.logo-display-container {
  margin: 0 0 1rem .5rem;
}

.logo-display-img {
  max-width: 300px;
  width: auto;
  max-height: 50px;
  height: auto;
  background-color: #DBEBFE;
}

.logo-img-size-description {
  margin-left: 1rem;
  font-family: Roboto;
  font-Weight: 300;
  font-size: 14px;
  color: #354052;
}

.logo-save-btn {
  margin: 1rem 0 1rem 2rem;
}