.info-modal-card {
  margin: auto;
  margin-top: 2rem;
  width: 90%;
  height: 95%;
}

.info-modal-button-card {
  margin: auto;
  margin-top: 1.5rem;
  width: 50%;
  height: 30%;
}

.info-modal-container {
  margin: auto;
  margin-bottom: 1.5rem;
}

.info-modal-grid-container {
  pointer-events: none;
}

.info-modal-drawer {
  box-shadow: inset 0 0 15px 0px #999;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin: auto;
  width: 20rem;
  height: 100%;
  background-color: #f1f3f5;
  border-radius: 2%;
}

.info-modal-heading {
  border-bottom: 1px solid #444;
  font-size: 1.25rem;
  font-weight: 500;
}

.info-modal-body-container {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

.info-modal-description-container {
  overflow-wrap: break-word;
}
.info-modal-fieldID-container {
  overflow-wrap: break-word;
  padding-bottom: 0;
  margin-bottom: 0;
}

.info-modal-form-example {
  color: #0077ff;
  padding: 1rem;
}

.info-modal-field-example {
  color: #485361;
  padding: 1rem;
}

.info-modal-fieldID-example {
  font-size: 20px;
  margin: auto;
  width: 50%;
  text-align: center;
}
