.login-preferences-section-heading {
  padding: 0 1.5rem;
  color: #354052;
  font-weight: 600;
}

.login-preferences-hr {
  border: 1px solid #ebedf8;
  width: 90%;
  margin: 0 1rem 1rem;
  padding-top: 0;
}

.login-preferences-signature-options-container {
  display: inline-block;
  padding-right: 0px;
}

.login-preferences-drivers-container {
  padding: 0 1.5rem;
  margin: 1rem 0 0;
}

.login-preferences-driver-content {
  margin: 0 0 0.5rem;
}

.login-preferences-driver-info {
  display: inline-block;
}

.login-references-btn-container {
  margin-top: 1rem;
  padding-bottom: 1rem;
  text-align: right;
  position: relative;
}

.modal-header {
  color: #ffffff;
  height: 4.375rem;
  background-color: #444a59;
  text-align: left;
  padding: 1.4rem 2rem 0 2rem;
}
