.manage-ancillary-section-title {
  color: #1a173b;
  font-family: Roboto;
  font-size: 21px;
  letter-spacing: 0.14px;
  align-self: center;
  padding-bottom: 10px;
  padding-top: 10px;
}

.manage-ancillary-product-detail-title {
  font-family: Roboto;
  font-size: 10px;
  line-height: 11px;
  color: #1a173b;
  letter-spacing: 1px;
}

.manage-ancillary-product-detail-text {
  color: #67727c;
  font-family: Roboto;
  font-size: 14px;
  line-height: 11px;
  padding-top: 5px;
}

.manage-ancillary-product-button-block {
  margin: 2rem;
  text-align: right;
  position: relative;
}
