.choicelist-modal-content {
  background-color: #fff;
  border-radius: 4px;
  display: block;
  height: 60%;
  width: 50%;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14),
    0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.choicelist-modal-header-container {
  display: flex;
  color: white;
  background-color: #444a59;
  border-radius: 4px 0;
  height: 70px;
  padding: 1rem;
}

.choicelist-search-container {
  width: 50%;
}

.choicelist-search-input {
  width: 65%;
}

.choicelist-header-icon {
  margin-right: 0.5em;
}

.choicelist-table-content {
  height: calc(100% - 8.5rem);
  overflow-y: auto;
  box-shadow: inset 0px 0px 5px -2px #000;
}

.choicelist-btns-container {
  margin-top: 15px;
  text-align: right;
  position: relative;
  padding-bottom: 15px;
}

/**
 * RESPONSIVE LAYOUT
 */
@media only screen and (max-width: 960px) {
  .choicelist-modal-content {
    width: 75%;
    height: 80%;
  }
}
