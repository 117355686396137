.mobile-setup-email-container {
  margin: 5px 0 0 2rem;
  display: inline-block;
  width: 36rem;
}

.mobile-setup-email-form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-setup-logo-container {
  margin: 1rem 0 0 -3rem;
}
