.tab-container {
  width: 100%;
  height: 100%;
}

.image-preview-list-menu-item-container {
  display: flex;
  justify-content: center;
}

.image-preview-list-container {
  min-height: 400px;
  width: 15%;
  display: inline-block;
  text-align: center;
}

.paper-container {
  width: 100%;
  height: 100%;
}
