.app-bar-container {
  min-width: 800px;
  position: relative;
  z-index: 5;
}

.paper-container {
  align-items: center;
  justify-content: center;
}

.paper-component-container {
  background-color: #f9f9f9;
  width: 100vw;
  height: 100vh;
}

.paper-widget-card-container {
  display: flex;
  max-height: 90vh;
  height: 90vh;
  overflow-y: auto;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #fafbfc;
  border-top: 1px solid #ebedf8;
}
