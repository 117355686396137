.taxpass-msg-container {
  bottom: 0;
  height: 40vh;
  overflow: auto;
  padding-bottom: 10px;
}

.taxpass-msg-footer {
  padding: 1rem;
  width: 100%;
}

.bubble-container {
  width: 100%;
}

.bubble-body-container {
  display: block;
}

.bubble-body-container.left {
  text-align: left;
}

.bubble-body-container.right {
  text-align: right;
}

.talk-bubble {
  margin: 0px 1rem 1rem 1rem;
  display: inline-block;
  position: relative;
  max-width: 70%;
  min-width: 20%;
  height: auto;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  padding: 1rem;
  text-align: left;
  line-height: 1rem;
  font-size: 0.9rem;
  overflow-wrap: break-word;
}

.bubble-arrow-right::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 5%;
  width: 0;
  height: 0;
  border: 1.1rem solid transparent;
  border-top-color: #e5e5e5;
  border-bottom: 0;
  border-left: 0;
  margin-left: 5%;
  margin-bottom: -1.1rem;
}

.bubble-arrow-left::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 5%;
  width: 0;
  height: 0;
  border: 1.1rem solid transparent;
  border-top-color: #d1d5e6;
  border-bottom: 0;
  border-right: 0;
  margin-right: 5%;
  margin-bottom: -1.1rem;
}

.date-separator-timestamp {
  width: 100%;
  padding: 0.5rem 2rem 0.5rem 2rem;
  font-size: 0.7rem;
  color: #273d52;
}

.bubble-message-timestamp {
  color: #354052;
  font-size: 0.75rem;
  width: 100%;
  padding: 0rem 2rem 0 2rem;
}

.receiver {
  background-color: #e5e5e5;
}

.sender {
  background-color: #d1d5e6;
}
