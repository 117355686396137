.modal-header {
  color: #ffffff;
  height: 4.375rem;
  background-color: #444a59;
  text-align: left;
  padding: 1.4rem 2rem 0 2rem;
}

.column-picker-icon {
  height: 1.75rem;
  width: 1.75rem;
  float: left;
  margin-left: 1.625rem;
  margin-right: 0.938rem;
}

.column-picker-heading {
  font-size: 1.313rem;
  color: #1a173b;
  margin-bottom: 0.5em;
  margin-left: 0.5em;
}

.column-picker-column-headings {
  font-size: 0.75rem;
  color: #1a173b;
  font-weight: normal;
}

.column-picker-button-group {
  text-align: right;
  margin-right: 3em;
  margin-bottom: 2em;
}
