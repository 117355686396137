.formviewer-container {
  height: 100%;
}

.formviewer-field-info-tab-container {
  position: sticky;
}

.wizard-field-info-tab-container {
  width: 20rem;
position: fixed;
right: 0;
z-index: 99;
background-color: #f1f3f5;
}

.preview-doc-archive-modal-container {
  padding: 0.5rem 1.5rem 0 1.5rem;
}
.preview-doc-archive-modal-container-table-td {
  width: 100%;
  height: 1rem;
}
.preview-doc-archive-modal-table-container-a {
  text-decoration: none;
  box-shadow: none;
  border: none;
}
.preview-doc-archive-modal-table-container-a-img {
  display: inline-block;
  vertical-align: -5px;
  height: 20px;
  width: 20px;
  margin-top: 5px;
  padding-left: 5px;
}
.preview-doc-archive-modal-table-container-a-btn {
  background-color: #ffffff;
  color: #0077ff;
  border: none;
  font-family: 'Roboto';
  font-size: 15px;
}
