.modal-header-row {
  margin-right: 0rem !important;
  margin-left: 0rem !important;
  margin-top: 0rem !important;
  justify-content: space-between;
}

/* filter value changes image to white */
.modal-multiply-img {
  filter: invert(100%) brightness(200%) contrast(100%);
  cursor: pointer;
}

.form-list-container {
  padding-left: 2rem;
  height: 42rem;
  overflow-y: auto;
}

.info-container-body {
  padding: 1.5rem;
  overflow-y: auto;
}
.container-body-question {
  font-weight: 600;
}
.container-body-message {
  padding: 2rem 0rem;
}

.modal-bottom-container {
  position: fixed;
  bottom: 1rem;
  display: flex;
  justify-content: flex-end;
}

.misc-form-row {
  height: 2rem;
}

.button-spacer {
  margin-left: 1em;
  margin-right: 1em;
}
