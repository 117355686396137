.mfa-method-content-block {
  padding-top: 3rem;
}
.mfa-method-logo-block {
  text-align: center;
}
.mfa-method-form-block {
  text-align: center;
  padding-left: 4rem;
}
.mfa-method-description {
  font-size: 1.3rem;
  float: left;
  margin-bottom: 0.5rem;
  color: #1a173b;
}
.mfa-method-radio-btn-block {
  width: calc(94%);
  float: left;
}
.mfa-method-radio-btn {
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: left;
}
.mfa-method-radio-btn-active {
  background-color: #f3f4f9;
}
.mfa-method-radio-btn-inactive {
  background-color: #ffffff;
}
.mfa-method-img-block {
  float: left;
  padding-right: 1rem;
}
.mfa-method-img-block-email {
  padding-top: 0.8rem;
}
.mfa-method-img-phone {
  width: 1.75rem;
  height: 2.1rem;
}
.mfa-method-img-message {
  width: 1.75rem;
  height: 1.4rem;
}
.mfa-method-code-block {
  text-align: left;
  margin-left: 2rem;
  padding-bottom: 0.5rem;
}
.mfa-method-email-block {
  float: none;
  margin-left: 3rem;
}
.mfa-method-email-description {
  padding-bottom: 0.5rem;
}
.mfa-method-selection {
  padding-left: 0.5rem;
}
.mfa-method-btn-block {
  text-align: right;
  width: 100%;
  padding: 0;
  margin-left: 0vw;
  margin-right: 3vw;
  margin-top: 3vh;
}
.mfa-method-btn-block-position {
  float: right;
}
