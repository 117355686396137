.disabled {
  background-color: lightgray;
}

.addFormModal {
  max-height: 330px;
  min-height: 330px;
  overflow-y: auto;
}

.addFormModalButton {
  height: 30px !important;
  width: 97px;
  border-radius: 4px !important;
  background-color: #ffffff !important;
  text-transform: capitalize !important;
}

.addAssetHeader {
  display: inline-flex;
}

.addAssetLabel {
  height: 50px;
  line-height: 50px;
}

.reassignAsset {
  max-height: 45vh;
  max-width: 50vw;
  padding: 0;
  position: relative;
  margin: auto;
  top: 20%;
}

.errorsAndRejectsButton {
  height: 30px !important;
  width: 97px;
  border: 1px solid #0077ff !important;
  color: #0077ff !important;
  border-radius: 4px !important;
  background-color: #ffffff !important;
  text-transform: capitalize !important;
}

.addDocumentModalButton {
  border-radius: 4px !important;
  background-color: #ffffff !important;
  text-transform: capitalize !important;
}

.modalTabContents {
  max-height: 35vh;
  min-height: 35vh;
  max-width: 80vw;
  overflow-y: auto;
}

.eventLogModal {
  max-height: 21.875rem;
  overflow-y: auto;
}

/* Reduce height on eventLogModal 50rem ~= 800px */
@media screen and (min-height: 50rem) {
  .eventLogModal {
    max-height: 21.875rem;
    min-height: 21.875rem;
    overflow-y: auto;
  }
}

.secondaryMenuItem {
  padding-right: 10px;
  display: none;
}

.secondaryMenuItem:hover {
  display: block;
}

.menuItem:hover + .secondaryMenuItem {
  display: block;
}

.contextMenuItem:hover {
  background-color: #444a59;
}

.snackbar-prompt {
  background-color: #444a59 !important;
  margin: 0 auto !important;
}

.snackbar-info {
  background-color: green !important;
  margin: 0 auto !important;
}

.snackbar-error {
  background-color: red !important;
  margin: 0 auto !important;
}

.snackbar-warning {
  background-color: #ffa000 !important;
  margin: 0 auto !important;
}

#simple-modal-title {
  color: white;
  background-color: #444a59;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 70px;
  padding-top: 22px;
  padding-right: 26px;
}

.NoHoverEffectButton:hover {
  background-color: white;
}

.blinking {
  animation: blinker 0.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.mainContainer {
  background: #f1f3f5;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  height: 100vh;
}
.rowC {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.guided-estimator-mainContainer {
  background: #f1f3f5;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  height: 93vh;
  width: 100%;
}

.terms-and-conditions-link {
  color: blue;
  text-decoration: none;
  cursor: pointer;
}
