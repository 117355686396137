.modal-footer {
  position: relative;
  bottom: 5%;
  margin: 0 0 3.5rem 0;
}

.modal-footer .btn-container {
  position: absolute;
  right: 5%;
}

.modal-subtitle {
  font-size: 1.3rem;
  margin: 1.5rem 0 2rem 0;
}
