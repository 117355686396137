.paginate-page,
.paginate-next,
.paginate-prev,
.paginate-break,
.paginate-activepage {
  border: 0.95px solid #c7cdd9;
  color: #b4bac6;
  padding-left: 5px;
  padding-right: 5px;
  margin-left: 5px;
  width: auto;
  cursor: pointer;
  font-family: Roboto;
  border-radius: 2.85px;
  user-select: none;
}

.paginate-activepage {
  background-color: #0077ff;
}

.paginate-page a,
.paginate-prev a,
.paginate-next a,
.paginate-disabled a {
  user-select: none;
  outline: none;
  color: #b4bac6;
}

.paginate-activepage a,
.paginate-activepage a:hover,
.paginate-disabled a:hover,
.paginate-disabled a:focus {
  user-select: none;
  outline: none;
  color: white;
  text-decoration: none;
}

.paginate-disabled a:hover,
.paginate-disabled a:focus {
  color: #b4bac6;
}

.paginate-prev a:hover,
.paginate-next a:hover,
.paginate-page a:hover {
  outline: none;
  user-select: none;
}

.paginate-break,
.paginate-disabled {
  cursor: auto;
}

.paginate-hide {
  display: none;
}

.pagination {
  margin: 0 7rem 0 0;
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.paginate-page-count-container {
  flex: 0;
  display: flex;
  justify-content: flex-start;
  margin: 0;
}

.paginate-page-count {
  height: 18px;
  width: 335px;
  color: #aab2c0;
  font-family: Roboto;
  font-size: 15.2px;
  line-height: 18px;
  padding: 0;
}

.paginate-container {
  display: flex;
  overflow: hidden;
  padding-bottom: 0.5rem;
}
