.mfa-code-content {
  padding-top: 3rem;
}
.mfa-logo-container {
  text-align: center;
}

.mfa-code-fields-container {
  text-align: left;
  padding-left: 3rem;
}

.mfa-notice {
  font-size: 14px;
  padding-right: 3rem;
  padding-top: 4rem;
}

.mfa-notice-color-red {
  color: red;
}

.mfa-enter-code-heading {
  padding-bottom: 0.5rem;
  font-size: 1.3rem;
}

.mfa-code-issue-support-link {
  color: #0077ff;
  cursor: pointer;
  padding-left: 1rem;
  font-size: 15px;
}

.mfa-mark-public-checkbox-container {
  margin-left: 1.3vw;
}
.mfa-back-continue-btn-container {
  width: 100%;
}
.mfa-back-continue-btn-content {
  float: right;
}

.mfa-btn-spinner {
  margin-right: 0.5em;
  display: flex !important; /* override MUI styles */
}
