.login-form-container {
  width: 28rem;
  display: block;
  margin: 0 auto;
}

.login-image {
  height: 2.8rem;
  margin: 0 auto 2rem;
  display: block;
}

.recommended-browser-container {
  margin-top: 5rem;
}

.recommended-browser-note {
  color: red;
  font-weight: 600;
}

/* iPad landscape */
@media screen and (min-width: 768px) {
  .login-crosslink-logo {
    display: flex;
  }
}

.login-form-control-block {
  margin-left: 1.2vw;
  margin-top: -0.5vw;
}

.login-btn-block {
  text-align: right;
}

.login-log-in-btn {
  text-align: left;
  height: 1.5rem;
  color: #1a173b;
  font-family: Roboto;
  font-size: 1.3rem;
  letter-spacing: 0.14px;
  line-height: 1.5rem;
}

.login-dev-info-block {
  text-align: center;
  position: relative;
  top: 5em;
}

.login-btn-spinner {
  margin-right: 0.5em;
}
