.field-info-drawer {
  box-shadow: inset 0 0 15px 0px #999;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 20rem;
  height: 100%;
}

.field-info-drawer.hide {
  width: 0;
  padding: 0;
}

.field-info-drawer.open {
  right: 0;
}

.field-info-body-container {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

.field-info-description-container {
  overflow-wrap: break-word;
}

.field-info-fieldID-container {
  overflow-wrap: break-word;
  padding-bottom: 0;
  margin-bottom: 0;
}

.field-info-form-links-container {
  padding: 0;
  height: calc(100% - 17rem);
  overflow-y: auto;
  box-shadow: inset 0 0 5px #999;
  border-radius: 5px;
}

.field-info-heading {
  border-bottom: 1px solid #444;
  font-size: 1.25rem;
  font-weight: 500;
}

.field-info-worksheet-container {
  padding: 1rem 0;
}

.field-info-worksheet-container > button {
  margin-right: 2rem;
}

.field-info-help-container {
  bottom: 0;
  padding: 0 0 1rem;
  position: sticky;
}

.field-info-help-number {
  font-weight: 500;
}

.field-info-drawer-estimator {
  box-shadow: inset 0 0 15px 0px #999;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 2rem 0.5rem 0.5rem;
  width: 100%;
  min-width: 20rem;
  height: 101%;
}
