.refund-side-bar-forms-content {
  height: 100%;
}

.refund-form-nav-container {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.refund-sidebar-form-nav {
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  overflow-y: auto;
}

.fed-refund-amt {
  padding: 0.5rem 1rem;
  margin-left: 1em;
}

.refund-display {
  margin-top: 1rem;
}

.state-display{
  padding-left: 1.5rem;
  margin-left: 1rem;
  color: #0077FF;
}

.collapse-size{
 max-height: 20vh;
 overflow-y: auto;
}