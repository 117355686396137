.textlink-received-modal-toolbar {
  background-color: #444a59;
}

.textlink-received-modal-grid {
  z-index: 99;
}

.textlink-received-modal-msg-container {
  position: relative;
  top: -25px;
  margin-top: 2em;
  margin-left: 35px;
  margin-right: 0.5rem;
}

.textlink-received-modal-btn-container {
  text-align: right;
  margin-top: -0.5rem;
  margin-right: 2.5rem;
  margin-bottom: 1rem;
}
