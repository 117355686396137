.render-form-section-div-style {
  margin-top: 2%;
  margin-bottom: 2%;
  display: inline-block;
}

.render-form-section-image-style {
  transform: scale(1.5, 1.5);
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome and Opera */
}

.attach-doc-secttion-image-container {
  display: inline-block;
  height: 0;
}

.render-form-section-div-two {
  display: inline-block;
  vertical-align: text-bottom;
  margin-left: 10%;
  margin-top: -25%;
}

.attach-doc-header {
  padding-left: 0.5em;
  vertical-align: middle;
}

.attach-doc-img-one {
  transform: scale(1, 1);
  height: 25px;
  width: 25px;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome and Opera */
}

.attach-doc-instructions-img {
  max-width: 20px;
  max-height: 20px;
}

.attach-doc-instructions-img-container {
  display: inline-block;
  vertical-align: top;
}

.attach-doc-instructions-text-container {
  display: inline-block;
  margin-left: 1em;
  vertical-align: middle;
  max-width: 95%;
}

.attach-doc-div-one {
  height: auto;
  margin-left: 3.5%;
  margin-right: 2.5%;
}

.attach-doc-div-two-grid-five-dropdown-options {
  width: 100%;
  margin-top: 0;
}

.attach-doc-div-two {
  display: flex;
  width: auto;
}

.attach-doc-div-two-grid-two-input-img-desc {
  width: 90%;
  margin-top: 0;
}

.attach-doc-div-two-grid-two-input-file {
  display: none;
}

.attach-doc-div-three {
  height: 1px;
  background-color: grey;
  width: 150%;
  margin-top: 1em;
}

.attach-doc-div-four {
  margin: 0 0.4rem 1.8rem 0;
  display: flex;
  justify-content: space-between;
}

.attach-doc-div-five {
  margin-top: 2%;
}

.add-doc-label {
  text-align: left;
  font-size: 14px;
}

.add-doc-display {
  display: none;
}

.tva-progress-bar {
  padding-top: 1rem; 
  padding-bottom: 1.5rem;
}

.tva-progress-header {
  justify-content: center !important;
  align-items: center !important;
}

.tva-progress-wheel {
  margin-left: 10px !important;
}