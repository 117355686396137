.login-modal-form {
  margin-left: 3vw;
  margin-right: 3vw;
  margin-top: 1vh;
}

.login-modal-div-one {
  text-align: left;
  margin-top: 2vh;
}

.login-modal-div-login-details {
  color: #1a173b;
  font-family: Roboto;
  font-size: 21px;
  letter-spacing: 0.14px;
  margin-bottom: 1vh;
}

.login-modal-div-two {
  float: left;
  margin-right: 3vw;
}

.login-modal-div-three {
  margin-right: 30px;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 0px;
}

.login-modal-row {
  margin-right: 30px;
  margin-left: 1vw;
  margin-top: 10px;
}

.login-modal-row-two {
  margin-right: 30px;
  margin-left: 1vw;
  margin-top: 10px;
  margin-bottom: 1vh;
}

.login-modal-div-margin {
  margin-bottom: 0.5vh;
}

.login-modal-span {
  color: #354052;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
}
