.breadcrumbs {
  font-size: 12px;
  padding-left: 0em;
  z-index: 9999;
  position: fixed;
  width: 100%;
}

.breadcrumb-list {
  list-style: none;
  padding-left: 0.5em;
  margin-bottom: 0;
  height: 20px;
  background-color: #444a59;
  color: white;
}

.training-breadcrumb-list {
  list-style: none;
  padding-left: 1.5em;
  margin-bottom: 0;
  font-family: 'Roboto';
  font-weight: 500;
  letter-spacing: 0.08px;
  font-size: 13px;
  height: 20px;
  background-color: #ffcc11;
  color: black;
}

.breadcrumb-list-item {
  display: inline;
}

.breadcrumb-cursor {
  cursor: pointer;
}

.breadcrumb-link {
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
  padding-left: 0.2em;
}

.breadcrumb-nolink {
  display: inline-block;
  text-decoration: underline;
}

.breadcrumb-nolink-nounderline {
  display: inline-block;
}

.breadcrumb-demo-black,
.breadcrumb-demo-black:hover {
  color: #000000;
}

.breadcrumb-demo-white,
.breadcrumb-demo-white:hover {
  color: #ffffff;
}

.breadcrumb-div {
  display: inline;
  position: absolute;
  right: 3em;
}
