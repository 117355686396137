.new-preparer-section-headings {
  font-family: Roboto;
  font-size: 21px;
  letter-spacing: 0.14px;
  color: #1a173b;
  margin-left: 1vw;
  text-align: left;
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.new-preparer-form {
  margin: 1rem 2rem 2rem;
}

.new-preparer-form-action-btn {
  text-align: right;
  width: 100%;
  margin: 0;
  position: sticky;
  bottom: 0;
  background-color: #fff;
  padding: 1rem 2rem;
  border-top: 1px solid #ddd;
  box-shadow: 0 0px 5px 0px #bbb;
}
