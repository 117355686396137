.header-bar-block {
  flex-grow: 1;
}

.header-bar-nav-item,
.header-bar-nav-item:hover {
  text-decoration: none;
  color: #1a173b;
}

.search-nav-label {
  cursor: pointer;
  border: none;
}

.toolbar-color {
  background-color: #ffffff;
  height: 65px;
  gap: 2rem;
}

.acct-profile-title-bar-container {
  padding-right: 11px;
}

.header-bar-logo-block {
  max-width: 12rem;
  width: 12rem;
}

.header-bar-search-bar-block {
  width: 31.5rem;
  height: 100%;
}

.header-bar-search-bar-block-training {
  margin-top: 1.1rem;
  height: 3.9rem;
}

.header-bar-help-block {
  display: flex;
}
