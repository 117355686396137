.irs-message-block {
  margin-left: 5rem;
  margin-right: 5rem;
}

.irs-message-block * {
  box-sizing: unset;
}

.irs-message-paragraph-bold {
  font-weight: 500;
}

.irs-message-paragraph-spacing {
  margin-bottom: 1rem;
}

.irs-message-paragraph-section {
  margin: 1rem 0 2rem;
}

.irs-message-paragraph-subsections {
  padding-left: 2rem;
}

.irs-message-list-spacing {
  margin: 1rem 0;
}