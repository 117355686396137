.import-stock-transactions-header {
  padding-left: 0.5rem;
  vertical-align: middle;
  font-weight: 100;
}
.import-stock-transactions-icon {
  transform: scale(1, 1);
  height: 1.562rem;
  width: 1.562rem;
  user-select: none;
}

.import-stock-transactions-body-block {
  height: 100%;
  margin-left: 3.5%;
  margin-right: 3.5%;
}

.import-stock-transactions-body-title {
  display: flex;
  width: 100%;
  padding: 1rem;
}

.import-stock-transactions-body-title-btn-block {
  display: flex;
  padding-top: 3%;
}

.import-stock-transactions-bottom-block {
  display: flex;
  width: 100%;
  margin-bottom: 3%;
  justify-content: space-between;
}

.import-stock-transactions-message-block {
  width: 80%;
  padding-left: 1rem;
}

.import-stock-transactions-btn-block {
  margin-top: 1%;
  display: flex;
  padding: 0 1rem 1rem 0;
}
