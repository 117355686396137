.database-content {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  margin-top: 2rem;
  height: calc(100% - 2rem);
  overflow: auto;
}

.database-form {
  margin-left: 3vw;
  margin-right: 3vw;
  margin-top: 1vh;
}

.database-form-top-content {
  text-align: left;
  padding-top: 3vh;
  padding-bottom: 1vh;
}

.database-left-content {
  float: left;
}

.database-form-top-add-new-btn {
  text-align: right;
  width: 100%;
  padding: 0;
  margin-left: 0vw;
  margin-right: 3vw;
}

.database-form-table-content {
  margin-top: 25px;
  margin-left: 2rem;
  width: calc(100% - 2rem);
  border-bottom: 1px solid #ddd;
}

.database-form-table-header {
  border-bottom: 1px solid #ddd;
}

.database-form-table-header-row {
  border: none;
  display: inline-block;
  width: 100%;
}

.database-form-table-header-column {
  font-family: Roboto;
  font-size: 12px;
  color: #1a173b;
  width: 20%;
  padding-bottom: 0%;
}

.database-form-table-body {
  display: block;
  height: auto;
  padding: 1rem 0;
}
