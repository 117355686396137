.remote-signature-preview-button-block {
  text-align: center;
  margin-left: 0vw;
  padding: 0;
  margin-top: 4vh;
}

/* ------------------------------------------------------------------ All Phones ------------------------------------------------------------------ */

@media only screen and (min-device-width: 320px) and (max-device-width: 835px) and (orientation: landscape) {
  .remote-signature-preview-button-block {
    position: absolute;
    left: 50%;
    top: 83%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin-top: 0;
  }
}
