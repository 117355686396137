.remote-sign-spinner-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/**
 * REMOTE SIGN VALIDATE CONTAINER
 */
.remoteAuthenticationPage {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
}

.remote-sign-container {
  height: fit-content;
  padding: 3rem;
  max-width: 30rem;
  width: 100%;
  display: grid;
  box-shadow: none;
  background-color: #fafbfc;
}

.remote-sign-horizontal-line {
  border: 1px solid #ebedf8;
  margin-top: 1.5vh;
  margin-bottom: 0.75vh;
}

/**
 * DOWNLOAD DOC TO DEVICE CONTAINER
 */
.remote-sign-download-doc-container {
  text-align: center;
  padding: 1rem;
  min-height: 100vh;
  width: 100%;
}

.remote-sign-pdf-return-view-container {
  width: 100%;
  height: calc(100vh - 13rem);
}

.remote-sign-pdf-return-view {
  height: 100%;
  width: 100%;
}

.remote-sign-document-block {
  overflow-y: scroll;
  height: calc(100vh - 17rem);
}

.remote-sign-document-block-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/**
 * REMOTE SIGNATURE CAPTURE
 */
.remote-sign-on-screen-container {
  width: 37.125rem;
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
}

.remote-sign-on-screen-capture-content,
.remote-sign-signature-preview-content {
  height: auto;
  min-height: 19rem;
  width: 570px;
  margin: auto;
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
}

.remote-sign-capture-signature-form,
.remote-sign-signature-preview-form {
  padding: 1rem 2rem;
}

.remote-sign-capture-signature-pad-container {
  background-color: rgb(219, 235, 254);
  text-align: center;
}

/**
 * REMOTE SIGNATURE PREVIEW
 */
.remote-sign-signature-preview-img-container {
  width: 100%;
}

.remote-sign-signature-preview-img {
  background-color: white;
  height: 100px;
  width: 100%;
}

.remote-sign-rotate-device-block {
  position: absolute;
  top: 33%;
  text-align: center;
}

.remote-sign-rotate-device-content {
  padding-bottom: 1rem;
}

.remote-sign-rotate-img {
  height: 75px;
  width: 75px;
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .remote-signature-pad-preview-content {
    height: 100%;
    width: 570px;
    margin: auto;
  }
  .remote-sign-on-screen-container {
    width: 100%;
  }
}

/* ----------- Tablet 12.9" ----------- */
/* Portrait */
/* Tablets 9.7" - 10.5" */
@media only screen and (min-device-width: 768px) and (max-device-width: 834px) and (orientation: portrait) {
  .remote-sign-on-screen-container {
    width: 100%;
  }
}
/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .remote-sign-on-screen-container {
    width: 100%;
  }
}

/* ----------- Tablet 10.5" ----------- */

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
@media only screen and (min-device-width: 834px) and (max-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .remote-sign-on-screen-container {
    width: 100%;
  }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .remote-sign-on-screen-container {
    width: 100%;
  }
}

/* ----------- Tablet 9.7" ----------- */

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .remote-sign-on-screen-container {
    width: 100%;
  }
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .remote-sign-on-screen-container {
    width: 100%;
  }
}

@media only screen and (min-device-width: 580px) and (max-device-width: 835px) and (orientation: landscape) {
  .remote-signature-pad-text-container {
    max-height: 3rem;
    overflow-y: auto;
  }
  .remote-signature-pad-paragraph {
    margin-bottom: 0.5rem;
  }
  .remote-sign-on-screen-container {
    width: 100%;
  }
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 960px) and (orientation: portrait) {
  .remote-sign-pdf-return-view {
    width: 100%;
  }

  .remote-sign-on-screen-capture-content,
  .remote-sign-signature-preview-content {
    width: 100%;
    height: 285px;
  }
}

.remote-sign-log-in {
  text-align: center;
  height: 24px;
  color: #1a173b;
  font-family: Roboto;
  font-size: 17.5px;
  letter-spacing: 0.14px;
  line-height: 24px;
  font-weight: 500;
}

/* ------------------------------------------------------------------ All Phones ------------------------------------------------------------------ */

/* Portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: portrait) {
  /**
 * REMOTE SIGN VALIDATE CONTAINER
 */
  .remoteAuthenticationPage {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    height: 100%;
    width: 100%;
  }
  .remote-sign-log-in {
    text-align: center;
    height: 24px;
    color: #1a173b;
    font-family: Roboto;
    font-size: 17.5px;
    letter-spacing: 0.14px;
    line-height: 24px;
    font-weight: 500;
    margin-top: 2rem;
  }
  .remote-sign-horizontal-line {
    border: 1px solid #ebedf8;
    margin-top: 1.5vh;
    margin-bottom: 4.75vh;
  }
  .remote-sign-pdf-return-view-container {
    width: 100%;
    height: calc(100vh - 19rem);
  }
  .remote-sign-download-doc-container {
    text-align: center;
    padding: 1rem;
    min-height: 100%;
    height: 100%;
    width: 100%;
  }
  .remote-sign-document-block {
    overflow-y: auto;
    height: calc(100vh - 22rem);
  }
}

/* Landscapre */
@media only screen and (min-device-width: 375px) and (max-device-width: 835px) and (orientation: landscape) {
  .remoteAuthenticationPage {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    height: 100%;
    width: 100%;
  }
  .remote-sign-on-screen-capture-content,
  .remote-sign-signature-preview-content {
    height: 100vh;
    width: 100%;
    margin: auto;
    position: fixed;
    z-index: 999;
  }
  .remote-sign-on-screen-container {
    width: 100%;
  }
  .remote-sign-capture-signature-pad-container {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .remote-sign-capture-signature-pad-container-disclaimer {
    top: 64%;
  }
  .remote-sign-signature-preview-img-container {
    width: 70%;
    text-align: center;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .remote-sign-document-block {
    overflow-y: auto;
    height: calc(100vh - 16rem);
  }
  .remote-sign-download-doc-container {
    overflow-y: scroll;
    height: 100vh;
    min-height: -webkit-fill-available;
  }
}

.request-sign-container {
  height: fit-content;
  padding: 1rem 1rem;
  max-width: 30rem;
  width: 100%;
  display: grid;
  box-shadow: none;
  background-color: #fafbfc;
}
