.container-item-icon {
  height: 1rem;
  width: 1rem;
  margin: 1rem 0.5rem 0 0.5rem;
  cursor: pointer;
}
.container-item-warning-icon {
  height: 1.75rem;
  width: 1.75rem;
  margin: 0 0.75rem 0 0;
}
.mapper-grid-container-grid-item-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 1.5rem;
}

.grid-container-grid-item-div-checkbox-no-margin {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}
.grid-container-div-title-warning {
  display: flex;
  align-items: center;
  min-height: 1.5rem;
  height: fit-content;
  width: 100%;
  padding: 1.5rem;
  margin-bottom: 1rem;
  background: #f1f3f5;
}
.div-label-item-right {
  margin-left: 0.75rem;
  font-size: 15px;
}
.div-label-item-left-bold {
  margin-right: 0.25rem;
  margin-left: 0.5rem;
  font-weight: 500;
  font-size: 15px;
}
.grid-highlight-item {
  background: #f1f3f5;
  padding: 0.5rem;
  margin: 1rem 0;
}
.div-label-item-right-bold {
  margin-left: 0.25rem;
  font-weight: 500;
  font-size: 15px;
}
.div-leader-line {
  border-bottom: 2px dotted;
  font-size: 0.65rem;
  border-color: #7c7e80;
}
.div-label-item-left-indent {
  margin: 0 0.25rem 0 2rem;
  font-size: 15px;
}
.MuiList-padding-993 {
  max-height: 25rem;
}
.MuiFormHelperText-root-1165 {
  margin-top: -0.15rem;
  margin-left: 0.4rem;
}
