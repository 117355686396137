.preview-signature-span-one {
  font-family: Roboto;
  font-size: 18px;
}

.preview-signature-form {
  margin-left: 3vw;
  margin-right: 3vw;
  margin-top: 1vh;
}

.preview-signature-div-one {
  text-align: left;
  margin-top: 3vh;
}

.preview-signature-paragraph-one {
  text-align: justify;
  font-size: 15px;
}

.preview-signature-div-two {
  text-align: center;
  width: 420px;
  height: 100px;
}

.preview-signature-image {
  width: 420px;
  height: 100px;
  background-color: white;
}

.preview-signature-div-three {
  text-align: center;
  padding: 0;
  margin-left: 0vw;
  margin-top: 4vh;
}
