.body {
  padding: 1rem 2rem 1rem 2rem;
  background-color: #fafbfc;
  display: flex;
  height: 100%;
  width: 100%;
}

.body-content {
  width: 100%;
}

.body-with-sidebar {
  margin-left: 95px; /* width of sidebar + buffer */
}

.body-without-sidebar {
  margin-left: 0;
}

.textLink {
  font-size: 15px;
  letter-spacing: 0.1px;
  color: #0077ff;
  display: inline;
  cursor: pointer;
}

.forgotUsername {
  float: right;
  white-space: nowrap;
  height: 19px;
  width: 122px;
  color: #0077ff;
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
}

.forgotPass {
  float: right;
  white-space: nowrap;
  height: 19px;
  width: 122px;
  color: #0077ff;
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
}

.verifyAccountForm {
  max-width: 450px;
}

.forgotUserPassForm {
  max-width: 450px;
}

#mfaSelectContainer {
  margin-top: 119px;
}

#mfaEnterCodeContainer {
  margin-top: 119px;
}

#mfaReviewDeviceContainer {
  margin-top: 119px;
}

.loginButton {
  text-align: right;
  position: relative;
  top: 50%;
  transform: translateY(35%);
}

.forgotPass {
  text-align: right;
  margin-left: 350px;
  width: auto;
}

.forgotUsername {
  text-align: right;
  margin-left: 350px;
  width: auto;
}

.logoCrosslink {
  height: 45px;
  width: auto;
  margin-bottom: 40px;
  left: -20px;
}

.logoEmptyLists {
  height: 64px;
  width: auto;
  margin-bottom: 23px;
  margin-top: 209px;
}

.textEmptyLists {
  color: #8a96a0;
  font-family: Roboto;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  margin-bottom: 20rem;
}

.bottomSectionEmptyLists {
  margin-bottom: 408px;
}

.btnAddTaxPreparerBlankTable {
  padding-right: 23px !important;
  padding-left: 23px !important;
}

.btnAddNewOfficeBlankTable {
  padding-right: 23px !important;
  padding-left: 23px !important;
}

.log-in-username {
  text-align: left;
  height: 24px;
  color: #1a173b;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0.14px;
  line-height: 24px;
}

.btnOptionsHeaderBarForms {
  border-radius: 4 !important;
  border: 0 !important;
  height: 35px !important;
  padding: 0 0px !important;
  min-height: 0px !important;
  min-width: 0px !important;
  float: right !important;
}

.importTaxPassReturnDropdown {
  padding-right: 0em;
  padding-left: 0em;
  color: white;
}

.addReturnButton {
  text-transform: capitalize !important;
  margin-bottom: 15px !important;
  margin-right: 5px !important;
}

.rightJustifyFlex {
  display: flex;
  justify-content: flex-end;
}
.filterDismiss {
  float: right;
  padding-right: 10px;
}

.filterToggle {
  padding-left: 5px;
  display: inline-block;
}

.filterContainer {
  background-color: #ffe06e40 !important;
  height: auto;
}

.filterContent {
  padding-left: 10px;
}

.filterIcon {
  height: 22px;
  width: 22px;
}

.filterCount {
  display: inline-block;
}

.btnHideCopyright {
  margin-right: 1.5em;
  margin-bottom: 1.5em;
  padding-right: 0.5em;
  padding-left: 0.5em;
}

.centered-spinner {
  z-index: 10000;
  position: fixed;
  top: 50%;
  left: 50%;
}

.body-container {
  height: calc(100vh - 2rem);
  flex-grow: 1;
  padding-top: 4rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.body-main-block {
  padding: 1rem 2rem 1rem 2rem;
  background-color: #fafbfc;
  display: flex;
  height: 100%;
  width: 100%;
}

.body-router-link {
  text-decoration: none !important;
}
