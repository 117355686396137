.upsert-site-id-toolbar {
  background-color: #444a59;
}

.upsert-site-id-section-heading {
  color: #1a173b;
  font-family: Roboto;
  font-size: 21px;
  margin-bottom: 1em;
}

.upsert-site-id-label {
  color: #354052;
  text-align: left;
  font-family: Roboto;
}

.upsert-site-id-input {
  width: 20vw;
  height: 3.5vh;
}

.upsert-site-id-content {
  margin: 2em;
  text-align: left;
}

.upsert-site-id-content-site-code {
  float: left;
  margin-right: 1em;
}

.upsert-site-id-content-site-description {
  float: none;
  margin-right: 1em;
}

.upsert-site-id-button-block {
  text-align: right;
  margin: 2rem 2rem 2rem 0;
}

.upsert-site-id-button {
  width: 9em;
  font-size: 14px;
  border-radius: 4px;
  margin-left: 2em;
}
