.remoteAuthenticationPage {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
}

.request-docs-container {
  height: fit-content;
  padding: 1rem 1rem;
  max-width: 30rem;
  width: 100%;
  display: grid;
  box-shadow: none;
  background-color: #fafbfc;
}

.request-docs-horizontal-line {
  border: 1px solid #ebedf8;
  margin-top: 1.5vh;
  margin-bottom: 0.75vh;
}

.request-docs-body-text-container {
  text-align: center;
}

.request-docs-body-input-label {
  margin: -1rem 0 0 0.3rem;
  font-size: 0.85rem;
}
