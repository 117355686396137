.efiling-setup-settings-container {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: auto;
  margin-top: 2em;
}

.efiling-setup-content {
  margin-left: 3vw;
  margin-bottom: 0.5vh;
}

.efiling-setup-dropdown {
  margin-left: 0.5em;
}

.efiling-setup-content-info-btn {
  width: 1.3rem;
  cursor: pointer;
}
