.version-modal-container {
  width: auto;
  height: auto;
}

.version-modal-toolbar {
  background-color: #444a59;
  display: inline-block;
  width: 100%;
}

.version-modal-body {
  display: grid;
  text-align: center;
  margin-top: 4%;
  margin-bottom: 3%;
  font-size: 1rem;
}

.version-modal-grid {
  padding-top: 1rem;
}

.version-modal-body-title {
  font-size: 20px;
  font-weight: bold;
}

.version-modal-body-program-version {
  margin-top: 0.5rem;
}
