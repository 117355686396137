.current-year-transfer-table-container {
  height: 13rem;
  overflow-y: auto;
}

.current-year-transfer-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
}
.current-year-transfer-existing-rtn-icon {
  margin: 0 0 0.5rem 0.8rem;
  height: 1rem;
  width: 1rem;
}
