.sidenav {
  z-index: 1;
  height: 100%;
  position: fixed;
  margin-top: 4em;
  overflow-x: hidden;
  padding-top: 20px;
  /*width: 3em;*/

  /*IV*/
  width: 89px;
  border: 1px solid #ebedf8;
  border-radius: 4px 0 0 4px;
  background-color: #444a59;
}
.sidenav a {
  padding: 6px 20px 10px 17px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  text-align: center;
}
.sidenav a:hover {
  background: black;
}
.selected {
  background-color: #282c34 !important;
}
.sidebar-icon {
  max-width: 1.4rem;
  min-width: 1.41rem;
}
.selected img {
  filter: contrast(200%);
}
/* positioning for badge, overlay on icon */
.message-badge {
  position: relative;
  height: 16px;
  top: -10px;
  left: -5px;
  font-weight: 700;
  font-size: 10px;
  color: #ffffff;
  background-color: #f81c1c;
}
