.login-maintenance-msg-container {
  margin-right: 2rem;
  height: auto;
  text-align: center;
  border: 2px solid #e04242;
  border-radius: 5px;
  padding: 2rem 1rem;
}

.login-maintenance-msg-notice-label {
  display: block;
  font-size: 1.5rem;
  color: #e04242;
}

.login-maintenance-msg {
  display: block;
}