.payment-modal-btns-container {
  text-align: right;
  position: fixed;
  background: #fff;
  width: 100%;
  padding: 1rem;
  left: 0;
  bottom: 0;
  box-shadow: 0 -5px 5px -3px #999;
}
