.assign-preparer-heading {
  color: black;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 35px;
  font-size: 21px;
  letter-spacing: 0.14px;
  line-height: 24px;
}

.assign-preparer-modal-header {
  display: flex;
  flex-direction: row;
  color: white;
  background-color: #444a59;
  height: 70px;
  padding-top: 20px;
  padding-right: 26px;
  padding-left: 30px;
}

.assign-preparer-content {
  margin-left: 2em;
}

.assign-preparer-return-metadata {
  font-size: 14px;
  margin-left: 2.5em;
}

.transfer-return-modal-button-group {
  margin-left: 210px;
}
