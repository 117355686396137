.main-container {
  width: 28rem;
  padding: 20% 0;
  position: relative;
  margin: auto;
  top: 10%;
  height: auto;
}
.save-modal-toolbar {
  background-color: #444a59;
  display: inline-block;
  height: 2em;
  width: 100%;
}
.Btn {
  display: inline;
  margin-bottom: 3em;

}
.saveBtn {
  margin-right: 2em;
  display: inline;
}
.notSavingBtn {
  display: inline;
  margin-left: 2em;
}
.toolbar-width {
  width: 100%;
}
.header-text {
  font-size: 2em;
}
.prompt-text {
  color: black;
  display: inline-block;
  font-size: 1em;
  width: 100%;
  cursor: default;
}
.buffer-text {
  margin-bottom: 1%;
}
.save-modal-body-title {
  font-size: 20px;
  font-weight: bold;
}
.save-modal-body {
  color: #ffffff;
  font-family: Roboto;
  display: grid;
  text-align: center;
  font-size: 1rem;
}
