.care-provider-button-wrapper {
  text-align: right;
  padding: 0;
  margin-right: 2em;
  margin-bottom: 2em;
  margin-top: 2em
}

.care-provider-form-control-wrapper-1em {
  float: left;
  margin-right: 1em;
}

.care-provider-form-control-wrapper-2em{
  margin: 2em;
  text-align: left;
}

.div-style-float-none{
  float:none;
}

.divider-style-margin-top-1em{
  margin-top: 1em;
}

.form-margin {
  margin: 2em;
}

.section-heading {
  color: #1A173B;
  font-family: "Roboto";
  font-size: 21px;
  margin-bottom: 1em;
}