.side-bar-forms-content {
  height: 100%;
}

.form-nav-container {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.sidebar-form-nav {
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  overflow-y: auto;
}

.fed-refund-amt {
  padding: 0.5rem 1rem;
  margin-left: 1em;
}

.add-a-form-btn-container {
  background-color: #f1f3f5;
  padding: 1em 0;
  text-align: center;
  width: 100%;
  margin-top: auto;
}
