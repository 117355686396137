.printing-setup-row-header {
  font-family: Roboto;
  font-size: 14px;
  color: #354052;
  padding-bottom: 2%;
  font-weight: 500;
}

.printing-setup-row-header-center {
  font-family: Roboto;
  font-size: 14px;
  color: #354052;
  padding-bottom: 2%;
  font-weight: 500;
  text-align: center;
}

.printing-setup-titleStyle {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  margin-top: 3vh;
  color: #354052;
}

.printing-setup-titleStylePDF {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  padding-top: 2vh;
  color: #354052;
}

.printing-setup-titleStyleCheckPrinting {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  padding-top: 2vh;
  color: #354052;
}

.printing-setup-titleStyleTaxEstimator {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  margin-top: 2vh;
  color: #354052;
}

.printing-setup-adjustLineIncrementStyle {
  width: 5vw;
  height: 3.5vh;
}

.printing-setup-couponAmountStyle {
  width: 6rem;
  height: 3.5vh;
  margin-right: 3vw;
}

.printing-setup-tableRowStyle {
  font-family: Roboto;
  font-size: 14px;
  color: #354052;
  height: 1.6vh;
}

.printing-setup-labelWidthStyle {
  height: 1.6vh;
  color: #354052;
  text-align: left;
  font-size: Roboto;
  font-size: 14px;
}

.printing-setup-labelWidthStyleCheckbox {
  height: 2vh;
  color: #354052;
  text-align: left;
  font-size: Roboto;
  font-size: 14px;
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
  margin-right: 0;
  margin-left: 0;
}

.printing-setup-print-row-Checkbox {
  background-color: #f3f9ff;
}

.printing-setup-print-row-options-Checkbox {
  background-color: #f3f9ff;
  margin-right: 0.3vw;
}

.printing-setup-expand-icon {
  width: 1em;
  height: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.printing-setup-header {
  font-family: Roboto;
  font-size: 21px;
  letter-spacing: 0.14px;
  color: #1a173b;
  margin-left: 0.3em;
}

.printing-setup-return-table {
  margin-top: 3vh;
  margin-left: 3vw;
  width: 75%;
}

.printing-setup-menuItemStyle {
  padding-top: 0px;
  padding-bottom: 0px;
  color: #0077ff;
  height: 1.5em;
}

.printing-header {
  text-align: left; 
  padding-top: 3vh; 
  display: flex; 
}

.print-order-button{
  margin-left:auto;
}

.print-order-item {
    cursor: move; 
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}


.print-order-item:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
    background-color: transparent
}

.print-order-item:hover {
  background-color: #dedede;
}

.print-order-item-text {
  margin-top: 3px;
}