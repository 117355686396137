.AddReturnButton {
  border-radius: 4px;
  background: #0077ff;
  color: white;
  font-size: 14px;
  line-height: 16px;
  max-width: 9em;
  outline: 0px !important;
}
.margin-container-15 {
  margin: 0 0 15px 15px;
}

.ConfigureReturnListButton {
  height: 35px;
  width: 102px;
  border: 1px solid #0077ff;
  border-radius: 4px;
  background-color: #ffffff;
  color: #0077ff;
  max-width: 8em;
  margin-top: 0.5em;
  margin-left: auto;
  margin-right: 0;
}

.ReturnListColumn {
  height: 18px;
  width: 71px;
  color: #8a96a0;
  font-size: 15px;
  line-height: 18px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.ReturnListColumnHeader {
  height: 13px;
  width: 36px;
  color: #b4bac6;
  font-size: 10.45px;
  letter-spacing: 1.49px;
  line-height: 13px;
}

.ReturnListHeaderRow {
  padding-top: 1em;
}

.ReturnListHeader {
  color: #1a173b;
  font-size: 21px;
  letter-spacing: 0.14px;
  line-height: 29px;
  padding: 0.5em 0em 0.5em 0em;
}

.ReturnListPageButton {
  height: 28px;
  max-width: 28px;
  border-radius: 2.85px;
  background-color: #fafbfd;
  padding: 0px;
  margin-left: 0.5em;
}

.ReturnListPanel {
  border: 1px solid #ebedf8;
  background-color: #ffffff;
  height: auto;
}

.ReturnStatusButton {
  height: 30px;
  width: 65px;
  border: 1px solid #0077ff;
  border-radius: 4px;
  background-color: #ffffff;
  color: #0077ff;
}

.TaxReturnRow {
  transition: 0.5s;
}

.TaxReturnRow:hover {
  background-color: lightgray;
}

.filterRow {
  font-weight: normal;
  vertical-align: bottom;
  display: inline-block;
}

.activeColColor {
  background-color: rgb(243, 249, 255);
}

selectMinimal {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

.tax-returns-date-input {
  font-size: 15px;
  letter-spacing: 1px;
  height: 35px;
  color: #354052;
  border-radius: 4px;
  border: 1px solid #ebedf8;
  background-color: #f3f9ff;
  margin-left: 5px;
  width: 150px;
  padding-left: 3px;
  margin-right: 5px;
}

.tax-returns-date-error {
  letter-spacing: 1px;
  height: 35px;
  color: #354052;
  border-radius: 4px;
  border: 1px solid #f0a6a9;
  background-color: #ffdadc;
  margin-left: 5px;
  width: 150px;
}
