.actionButton {
  border-radius: 4px;
  background: #0077ff;
  color: white;
  font-size: 14px;
  line-height: 16px;
  margin-left: 1em !important;
  letter-spacing: 0.1px;
  height: 35px;
  outline: 0px !important;
  width: auto;
}

.actionButton:hover {
  cursor: pointer;
}

.actionButton span {
  color: #ffffff;
}

.actionButtonDisabled {
  border-radius: 4px;
  background: #0077ff !important;
  color: #b9bfca;
  font-size: 14px;
  line-height: 16px;
  margin-left: 1em !important;
  letter-spacing: 0.1px;
  height: 35px;
  outline: 0px !important;
  border: 1px solid #c0c5cf;
  background: #ffffff !important;
  width: auto;
  pointer-events: auto !important;
}

.clientActions {
  color: #0077ff;
  font-size: 15px;
  font-weight: 550;
  letter-spacing: 0.1px;
  line-height: 18px;
  padding-right: 0em;
  text-align: right;
  padding-left: 5px;
  margin-bottom: 1em;
}

.profileLabel {
  margin-right: 2em;
}

hr {
  margin-left: 1em;
  margin-right: 1em;
}

@media only screen and (max-width: 1280px) {
  .clientActionsNew {
    color: #0077ff;
    font-size: 14px;
    font-weight: 550;
    letter-spacing: 0.1px;
    line-height: 18px;
    padding-right: 0em;
    text-align: right;
    padding-left: 3px;
  }

  .ovLabelVariable {
    height: 24px;
    color: #354052;
    font-size: 15px;
    letter-spacing: 0.14px;
    line-height: 24px;
    padding: 0em 1em 0em 0em;
    font-weight: 500;
    display: 'block';
    white-space: nowrap;
  }
  .buttonVariable {
    font-size: 14px;
    margin-right: 0.25em;
  }
}

.profileLabelVariable {
  display: flex;
  white-space: nowrap;
  margin-right: 1em;
  font-size: 0.9vw;
}
@media only screen and (min-width: 1920px) {
  .profileLabelVariable {
    font-size: 17px; /*Max font size for menu bar labels*/
  }
}

@media only screen and (max-width: 1280px) {
  .profileLabelVariable {
    display: none;
    font-size: 12px;
  }
}

@media only screen and (min-width: 1281px) {
  .clientActionsNew {
    color: #0077ff;
    font-size: 15px;
    font-weight: 550;
    letter-spacing: 0.1px;
    line-height: 18px;
    padding-right: 0em;
    text-align: right;
    padding-left: 5px;
  }

  .ovLabelVariable {
    height: 29px;
    color: #354052;
    font-size: 18px;
    letter-spacing: 0.14px;
    line-height: 24px;
    padding: 0em 1em 0em 0em;
    font-weight: 500;
    display: 'block';
    white-space: nowrap;
  }
  .buttonVariable {
    font-size: 15px;
    margin-right: 0.25em;
  }
}

.taxReturnBar {
  height: '64px';
  position: 'fixed';
}
