.remote-signature-pad-preview-content {
  height: 100%;
  width: 570px;
  margin: auto;
}

.remote-signature-pad-text-container {
  display: inline-block;
  margin-left: 1em;
  vertical-align: middle;
  max-width: 95%;
}

.remote-signature-pad-paragraph {
  font-size: 15px;
  text-align: justify;
}

.remote-signature-pad-toolbar-title {
  font-family: Roboto;
  font-size: 18px;
}

.remote-signature-pad-button-block {
  text-align: center;
  padding: 0;
  margin-left: 0vw;
  margin-top: 2vh;
}

/* ----------- Tablet 12.9" ----------- */
/* Portrait */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) {
  .remote-signature-pad-preview-content {
    height: 100%;
    width: 570px;
    margin: auto;
  }
  .remote-signature-pad-button-block {
    text-align: center;
    padding: 0;
    margin-left: 0vw;
    margin-top: 1vh;
  }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* ----------- Tablet 10.5" ----------- */

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
@media only screen and (min-device-width: 834px) and (max-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .remote-signature-pad-preview-content {
    height: 100%;
    width: 570px;
    margin: auto;
  }
  .remote-signature-pad-button-block {
    text-align: center;
    padding: 0;
    margin-left: 0vw;
    margin-top: 1vh;
  }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* ----------- Tablet 9.7" ----------- */

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .remote-signature-pad-preview-content {
    height: 100%;
    width: 570px;
    margin: auto;
  }
  .remote-signature-pad-button-block {
    text-align: center;
    padding: 0;
    margin-left: 0vw;
    margin-top: 1vh;
  }
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

@media only screen and (min-device-width: 580px) and (max-device-width: 835px) and (orientation: landscape) {
  .remote-signature-pad-text-container {
    max-height: 3rem;
    overflow-y: auto;
  }
  .remote-signature-pad-paragraph {
    margin-bottom: 0.5rem;
  }
}

/* ------------------------------------------------------------------ All Phones ------------------------------------------------------------------ */

@media only screen and (min-device-width: 320px) and (max-device-width: 835px) and (orientation: landscape) {
  .remote-signature-pad-button-block {
    position: absolute;
    left: 50%;
    top: 83%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin-top: 0;
  }
  .remote-signature-pad-button-block-disclaimer {
    top: 89%;
  }
  .remote-signature-pad-text-container {
    display: inline-block;
    margin-left: 1rem;
    vertical-align: middle;
    max-width: 100%;
    max-height: 5rem;
    height: 5rem;
    overflow-y: auto;
  }
  .remote-signature-pad-preview-content {
    height: 100vh;
    width: 100%;
  }
}
