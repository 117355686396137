.widgetCard {
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #ebedf8;
  user-select: none;
}

.widget-control-box {
  display: inline-table;
  width: "100vw";
  height: "100vh";
  margin: 0 auto;
  border: "1px solid #e0dada";
}

.store-description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-family: "Roboto";
  color: #67727c;
  font-size: 15px;
  margin-bottom: 2.5em;
}

.animateImagePreviewList {
  animation: 1s fadein;
}

.widgetOnHover {
  opacity: 1;
  z-index: -1;
}

.widgetHover {
  opacity: 1;
  display: flex;
  justify-content: center;
  position: absolute;
  padding: 0px;
  height: 0px;
  width: 100%;
}
.widgetNoHover {
  display: none;
}

.widgetDragHandle {
  padding: 0px;
  height: 0px;
}

.widgetDragButton:active {
  cursor: grabbing !important;
}

.widgetDragButton:hover {
  cursor: grab;
}

.widgetDropDownArrow {
  color: #c0c5d2;
}

.widgetContentNoHover {
  opacity: 0;
}
.widgetContentHover {
  opacity: 1;
  padding: 5px;
}

.barchart-tooltip-helper {
}

.arrow_box {
  position: relative;
  background: #ffffff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
}
.arrow_box:after,
.arrow_box:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  border-color: #ffffff00;
  border-bottom-color: #ffffff;
  border-width: 8px;
  margin-left: -8px;
}
.arrow_box:before {
  border-color: #ffffff00;
  border-bottom-color: #ffffff;
  border-width: 14px;
  margin-left: -14px;
}

.hide-this-tooltip-please {
  opacity: 0;
  visibility: hidden;
}
