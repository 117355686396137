.addNewReturn-header-row {
  margin-right: 0rem !important;
  margin-left: 0rem !important;
  margin-top: 0rem !important;
  justify-content: space-between;
}

/* filter value changes image to white */
.addNewReturn-multiply-img {
  filter: invert(100%) brightness(200%) contrast(100%);
  cursor: pointer;
}

.addNewReturn-submit-buttons {
  margin-left: 0rem !important;
  display: flex;
  justify-content: space-evenly;
}
