.client-letter-setup-container {
  margin-top: 2.5rem;
}

.client-letter-setup-content {
  padding: 2rem;
}

.client-letter-section-title {
  border-bottom: 1px solid #ebedf8;
  padding: 0.5rem 1rem;
}

.client-letter-sub-section-content {
  margin-left: 2rem;
}

.bus-client-letter-setup-container {
  margin-top: 2.5rem;
}

.bus-client-letter-setup-content {
  padding: 2rem;
}

.bus-client-letter-section-title {
  border-bottom: 1px solid #ebedf8;
  padding: 0.5rem 1rem;
}

.bus-client-letter-sub-section-content {
  margin-left: 2rem;
}