.audit-config-form {
  margin-left: 3vw;
  margin-right: 3vw;
  margin-top: 1vh;
}

.audit-config-form-content {
  text-align: left;
  padding-top: 3vh;
  padding-bottom: 1vh;
}

.audit-config-form-section-header {
  float: left;
}

.audit-config-form-section-header-label {
  font-family: Roboto;
  font-size: 21px;
  letter-spacing: 0.14px;
  color: #1a173b;
  margin-left: 0.3em;
}

.audit-config-form-section-header-buttons {
  text-align: right;
  width: 100%;
  padding: 0;
  margin-left: 0vw;
  margin-right: 3vw;
}

.audit-config-form-sub-section-header {
  color: #354052;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  margin-left: 0.3vw;
  margin-bottom: 1.5vh;
}

.audit-config-form-sub-section-item {
  display: flex;
  margin-left: 3vw;
  margin-bottom: 1vh;
}

.audit-config-form-divider {
  text-align: right;
  width: 100%;
  height: 100%;
  padding: 0;
  margin-left: 0vw;
  margin-right: 3vw;
  margin-bottom: 2vw;
}

.audit-config-form-button-container {
  text-align: right;
  width: 100%;
  padding: 0;
  margin-left: 0vw;
  margin-right: 3vw;
  height: 100%;
  margin-top: 0vh;
  margin-bottom: 3vw;
}
