.forgot-username-content-block {
  padding-top: 3rem;
  padding-left: 2.75rem;
  text-align: center;
}

.forgot-username-logo {
  margin-bottom: 0px;
  margin-left: -1.25rem;
  height: 2.8rem;
  width: auto;
}

.forgot-password-form {
  margin-top: 1rem;
}

.forgot-password-email-block {
  text-align: left;
  padding-top: 1.5rem;
}
