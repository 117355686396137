.header-bar-forms-toolbar-container {
  z-index: 1000;
}

.header-bar-help-block {
  display: flex;
}

.header-bar-forms-flex-grow-one {
  flex-grow: 1;
}

.header-bar-forms-section-desktop {
  display: flex;
  align-items: center;
  color: #0077ff;
}

.header-bar-forms-return-img {
  margin-right: 12px;
  align-self: center;
  width: 1.4rem;
  height: 1.4rem;
}

.header-bar-forms-return-icon {
  color: #0077ff;
  width: 1.4rem;
  height: 1.4rem;
}

.header-bar-forms-return-dropdown-arrow-white {
  margin: 0 -0.2rem;
  padding: 0 -0.2rem;
  color: white;
}

.header-bar-tooltip-container {
  display: flex;
}

.header-bar-forms-label-style {
  transform: translateY(0.25rem);
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  min-width: 0px;
  color: #0077ff;
  margin-left: 0.2rem;
}

.header-bar-forms-label-style.disableBtnLabel {
  color: #5c5c5c;
}

.header-bar-forms-label-style.disableBtnLabel:hover {
  cursor: default;
}

.header-bar-forms-return-label {
  display: block;
}

.header-bar-forms-return-id {
  font-size: 0.85rem;
}

.header-bar-shadow {
  -webkit-box-shadow: 0 0 0 0.25rem white, 0 0.35rem 0.25rem rgb(68, 68, 68);
  -moz-box-shadow: 0 0 0 0.25rem white, 0 0.35rem 0.25rem rgb(68, 68, 68);
  box-shadow: 0 0 0 0.25rem white, 0 0.35rem 0.25rem rgb(68, 68, 68);
}

.header-bar-shadow-none {
  box-shadow: 0px 0px 0px 0px;
}

.ovLabelVariable.header-bar-forms-return-name-wrapper {
  height: fit-content;
}

@media only screen and (max-width: 80rem) {
  .ovLabelVariable.header-bar-forms-return-name-wrapper {
    line-height: unset;
  }
}

@media only screen and (max-width: 64rem) {
  .ovLabelVariable.header-bar-forms-return-name-wrapper {
    line-height: unset;
  }

  .header-bar-forms-return-label {
    text-overflow: ellipsis;
    width: 12rem;
    overflow: hidden;
  }

  .header-bar-forms-return-label.header-bar-forms-return-name:hover {
    overflow: visible;
    white-space: normal;
    width: auto;
    transition: width 1s ease-in;
  }
}
