.container-item-icon {
  height: 1rem;
  width: 1rem;
  margin: 0 0.5rem;
  cursor: pointer;
}
.container-item-warning-icon {
  height: 1.75rem;
  width: 1.75rem;
  margin: 0 0.75rem 0 0;
}
.grid-container-grid-item-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 1.5rem;
}
.grid-container-div-title-warning {
  display: flex;
  align-items: center;
  min-height: 1.5rem;
  height: fit-content;
  width: 100%;
  padding: 1.5rem;
  background: #f1f3f5;
}
.return-title-space-bottom {
  margin-bottom: 2.7rem;
}
.checkbox-item-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 0 0 !important;
}
