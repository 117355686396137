.option-license-assign:disabled {
  background-color: #b3b3b3;
}

.manage-licence-create-license-btn-wrapper {
  position: relative;
}

.multi-office-manage-license-title {
  margin-top: 1rem;
}

.license-used {
  font-size: 1rem;
  font-weight: 400;
  margin-left: 2rem;
}

.btn-license-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-license-header span {
  display: flex;
  align-items: center;
}

.btn-license-header span button {
  margin-right: 2rem;
}

.btn-license-header span div {
  width: 8rem;
}

.modal-header {
  color: #ffffff;
  height: 4.375rem;
  background-color: #444a59;
  text-align: left;
  padding: 1.4rem 2rem 0 2rem;
}
