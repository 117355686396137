.maincontainer-div-body {
  max-width: 1200px;
  min-width: 1200px;
  background: #ffffff;
  margin: 1rem 0 0;
  width: 100%;
  height: calc(100% - 7.9rem);
  padding: 2rem 3rem;
  overflow-y: auto;
}

.secondary-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
  background-color: #f1f3f5;
}

.modal-style {
  position: static;
}

.misc-modal-container {
  position: absolute;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  inset: 55% 40px 40px 55%;
  transform: translate(-50%, -50%);
  height: 52rem;
  width: 60rem;
  
}

.info-modal-container {
  position: absolute;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  transform: translate(-50%, -50%);
  min-height: 17rem;
  width: 35rem;
  left: 50%;
  top: 50%;
}

.secondary-sub-container {
  position: relative;
}

.wizard-formviewer-container{
  width:100%;
}

.secondary-header-container {
  display: flex;
  flex-direction: column;
}

.secondary-sub-container-message {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 108.5em;
  padding: 0.5em;
  color: black;
  background-color: #f8e0e0;
  border: 1px solid #ffb1bb;
  border-radius: 4px;
  font-family: Roboto;
  font-size: 15px;
  margin-top: 0.7rem;
}

.secondary-sub-container-message-icon {
  height: 26px;
  margin-top: 0.2em;
  padding-left: 1em;
}

.secondary-sub-container-message-text {
  padding-left: 1.5em;
}

.secondary-sub-container-message-user {
  color: #0077ff;
}

.wizard-footer-disabled {
  /* position: fixed;
  bottom: 0rem; */
  opacity: 0.5;
  pointer-events: none;
}

.wizard-footer {
  position: absolute;
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
  margin-bottom: 0.85rem !important;
  left: 0;
  background: white;
  box-shadow: -4px -5px 5px -5px #999;
  height: 6rem;
  bottom: 0;
}

.wizard-footer-enabled {
  position: sticky;
  bottom: 0rem;
}

.formviewer-container-grid {
  margin: -8px -8px -8px 0 !important;
  width: 100% !important;
}

.wizard-sidebar-menu-item{
  /* white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; */
}

