.outer-rectangle {
  height: 100%;
  width: 290px;
  background-color: #ffffff;
}

.account-outer-rectangle-scroll {
  max-height: calc(100vh - 7rem);
  overflow-y: auto;
}

.person-block {
  margin: 0px 0px 10px 31px;
}

.initial-block {
  height: 65px;
  width: 61px;
  border-radius: 4px;
  display: inline-block;
  margin-right: 1.5em;
  margin-top: 31px;
  vertical-align: top;
}

.initial-block-title-bar {
  height: 35px;
  width: 35px;
  border-radius: 4px;
  display: inline-block;
  margin-right: 0.5em;
  margin-top: 0px;
  vertical-align: top;
}

.initial-block-encrypted-pdf {
  height: 35px;
  width: 120px;
  border-radius: 4px;
  display: inline-block;
  margin-right: 0em;
  margin-top: 15px;
  vertical-align: top;
}

.initials {
  opacity: 0.75;
  color: #637280;
  font-family: 'Roboto';
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.17px;
  line-height: 33px;
  margin-top: 17px;
  text-align: center;
}

.avatar {
  height: 65px;
  width: 65px;
  border-radius: 50%;
}

.avatarTitleBar {
  height: 2.2rem;
  width: 2.2rem;
  border-radius: 50%;
}

.user-info-block {
  margin-top: 33px;
  vertical-align: top;
  display: inline-block;
}

.user-info-block-title-bar {
  margin-top: 2px;
  margin-left: 0px;
  vertical-align: top;
  display: inline-block;
}

.encrypted-pdf {
  margin-top: 2px;
  margin-left: 0px;
  vertical-align: top;
  display: inline-block;
}

.mobile-block {
  margin: 0px 0px 10px 31px;
}

.qr-code {
  height: 6rem;
}

.taxpass-url-container {
  /* color: #0077ff; */
  cursor: pointer;
}

/* .taxpass-url-text::hover {
  color: #0e7fe1;
} */

.mobile-info-block {
  vertical-align: top;
  display: inline-block;
  margin-top: 5px;
}

.line {
  box-sizing: border-box;
  height: 3px;
  width: 228px;
  border: 1px solid #ebedf8;
}

.link-block {
  margin-left: 31px;
}

.link-block-last {
  padding-bottom: 1rem;
}

[class*='link-text'] {
  height: 20px;
  opacity: 0.75;
  color: #0077ff;
  font-family: 'Roboto';
  font-size: 15px;
  letter-spacing: 0.1px;
  line-height: 20px;
  margin-bottom: 8px;
}

.link-text-long-name {
  margin-bottom: 27px;
}

.small-link-text {
  height: 20px;
  opacity: 0.75;
  color: #0077ff;
  font-family: 'Roboto';
  font-size: 12px;
  letter-spacing: 0.1px;
  line-height: 20px;
  margin-bottom: 8px;
}

.link-text:hover {
  color: #185ea4;
  cursor: pointer;
}

.link-text-top:hover {
  color: #185ea4;
  cursor: pointer;
}

.link-text-long-name:hover {
  color: #185ea4;
  cursor: pointer;
}

.small-link-text:hover {
  color: #185ea4;
  cursor: pointer;
}

.small-text {
  height: 16px;
  opacity: 0.75;
  color: #637280;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.08px;
  line-height: 16px;
}
.tax-year {
  height: 1rem;
  width: 6rem;
  color: #637280;
  font-family: Roboto;
  font-size: 0.8125rem;
  letter-spacing: 0.09px;
  line-height: 1rem;
}
.small-text-title-bar {
  height: 14px;
  color: #67727c;
  font-family: 'Roboto';
  font-size: 12px;
  letter-spacing: 0.08px;
  line-height: 14px;
}
.mobile-app-id {
  height: 19px;
  width: 110px;
  color: #354052;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.11px;
  line-height: 19px;
  white-space: nowrap;
}
.account-name {
  height: 19px;
  width: 110px;
  color: #354052;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.11px;
  line-height: 19px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}
.office-name {
  height: 18px;
  width: 110px;
  color: #354052;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 18px;
  white-space: nowrap;
}
.role-name {
  height: 15px;
  width: 110px;
  color: #67727c;
  font-family: Roboto;
  font-size: 13px;
  letter-spacing: 0.09px;
  line-height: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.large-text {
  height: 20px;
  opacity: 0.75;
  color: #354052;
  font-family: 'Roboto';
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 20px;
}

.large-text-title-bar {
  height: 18px;
  color: #67727c;
  font-family: 'Roboto';
  font-size: 15px;
  letter-spacing: 0.1px;
  line-height: 18px;
  letter-spacing: 0.1px;
}

/* hide up/down arrows ("spinners") on input fields marked type="number" */
.no-spinners {
  -moz-appearance: textfield;
}

.account-id-501080 {
  height: 15px;
  width: 204px;
  color: #637280;
  font-family: Roboto;
  font-size: 13px;
  letter-spacing: 0.09px;
  line-height: 15px;
}
.userIdentifiers {
  padding-top: 10px;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
