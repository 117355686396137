.new-return-modal {
  font-family: Roboto;
  font-size: 15px;
}

.new-return-modal-warning-icon {
  height: 28px;
  width: 28px;
  float: left;
  margin-left: 26px;
  margin-right: 15px;
}

.new-return-modal-heading {
  color: #8a96a0;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 35px;
}

.new-return-modal-button {
  color: #0077ff;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #0077ff;
  margin-right: 40px;
  margin-top: 55px;
  width: 97px;
}

.new-return-modal-button:disabled {
  color: gray;
  background-color: white;
  border-radius: 4px;
  border: 1px solid gray;
  margin-right: 40px;
  margin-top: 55px;
  width: 97px;
}

.new-return-modal-button-group {
  margin-left: 210px;
  margin-top: 15px;
}

.new-return-modal-form-field {
  height: 35px;
  border: 1px solid #b4bac6;
  border-radius: 4px;
  padding-left: 19px;
  background-color: #f3f9ff;
}

/* This is not ideal, however modal is laying over form viewer */
.ReactModal__Overlay {
  z-index: 10;
}

.new-return-modal-textLink {
  color: #0077ff;
  display: inline;
  cursor: pointer;
  margin-right: 40px;
}

.new-return-modal-error-message {
  height: 20px;
  padding-top: 5px;
  color: red;
  text-align: center;
}

.new-return-modal-label {
  color: #354052;
  font-size: 14px;
  text-align: right;
}
