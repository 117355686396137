.ero-setup-container {
  margin-top: 2.5rem;
}

.ero-setup-heading {
  text-align: left;
  padding-top: 3vh;
  padding-bottom: 1vh;
}

.ero-setup-heading-span {
  font-family: Roboto;
  font-size: 1.3125rem;
  letter-spacing: 0.00875rem;
  color: #1a173b;
}

.ero-setup-heading-span-note {
  font-size: 1rem;
}

.ero-setup-heading-content {
  margin-left: 1vw;
}

.ero-setup-form {
  margin-left: 3vw;
  margin-right: 3vw;
  margin-top: 1vh;
}

.ero-setup-checkbox-label {
  margin-left: 3vw;
  margin-bottom: 1vh;
}

.ero-setup-input-container {
  float: left;
  margin-left: 2vw;
}

.ero-setup-input-container-left {
  float: left;
}

.ero-setup-input-display-none {
  margin-left: 2vw;
  display: none;
}

.ero-setup-menu-item {
  padding-top: 0rem;
  padding-bottom: 0rem;
  color: #0077ff;
  background-color: #ffffff;
}

.ero-setup-menu-entry {
  padding-left: 0.75rem;
}

.ero-setup-form-container {
  width: min-content;
}
