.modal-top-20 {
  top: 20%;
}

.capture-sig-paper-modal-container {
  width: 35%;
  padding: 0;
  position: relative;
  margin: auto;
}

.capture-sig-toolbar-label {
  font-family: 'Roboto';
  font-size: 18px;
}

.capture-sig-user-info-text {
  margin-left: 2vw;
  margin-right: 2vw;
  margin-top: 1vh;
  padding-top: 1vh;
  padding-bottom: 1vh;
}

.capture-sig-ok-btn {
  text-align: center;
  padding-bottom: 2vh;
}

.welcome-heading-text {
  float: left;
  font-family: 'Roboto';
  font-size: 15px;
  font-weight: 500;
  color: #1a173b;
  margin-top: 0.75em;
  width: 33%;
  margin-right: auto;
  margin-left: 0em;
}

.welcome-heading-text-centered {
  float: left;
  font-family: 'Roboto';
  font-size: 15px;
  font-weight: 500;
  color: #1a173b;
  margin: auto;
}

.welcome-linear-progress-container {
  float: left;
  margin-right: auto;
  margin-left: 0em;
}

.welcome-linear-progress-content {
  margin-top: 2em;
  width: 37em;
}

.welcome-setup-link {
  float: none;
  width: 13%;
  margin-right: 0em;
  margin-left: auto;
  margin-top: 1.25em;
  text-decoration: none;
}

.add-new-widget-text-align-center {
  text-align: center;
}

.horizontal-rule {
  margin-right: auto;
  margin-left: auto;
  width: 42%;
  display: inline-flex;
}

.add-new-widget-btn {
  border: none;
  margin-top: 1em;
  top: -18px;
}

.tax-return-list-icons {
  position: relative;
  padding-left: 0.5rem;
}

.preview-table-msg {
  margin-left: 1.9rem;
  font-weight: bold;
}
