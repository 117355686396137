.terms-and-conditions-btn-block {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 2rem 0 2rem 2rem;
  background-color: #fff;
}

.terms-and-conditions-container {
  height: 100%;
  width: 100%;
}

.terms-and-conditions-body {
  padding: 2rem;
  text-align: center;
}

.terms-and-conditions-cancel-btn {
  position: absolute;
  right: 1.5rem;
}

.terms-and-conditions-preview-pdf {
  height: 93%;
  width: 100%;
  padding: 1rem;
}

.terms-and-conditions-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
