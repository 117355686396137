.footer {
  position: fixed;
  width: 100%;
  color: #aab2c0;
  height: 1.5rem;
  bottom: 0rem;
  text-align: center;
  background-color: #ffffff;
  position: fixed;
  width: 100%;
}

.footer-Year {
  padding-right: 0.5rem;
  color: #67727c;
}

.footer-Version {
  padding-right: 0.5rem;
  padding-left: 1rem;
  color: #67727c;
}

.footer-Version-Number {
  padding-right: 0.5rem;
}

.footer-Copyright {
  margin-top: 0;
  padding-left: 0.93rem;
}

.footer-Link {
  margin: 0rem 0.25rem 0rem 0.25rem;
  color: #0077ff;
  cursor: pointer;
}
.footer-Link-Privacy-Policy {
  padding-right: 0.5rem;
  padding-left: 1rem;
  margin: 0rem 0.25rem 0rem 0.25rem;
  color: #0077ff;
  cursor: pointer;
}

.footer-TextAlignJustify {
  text-align: justify;
}
