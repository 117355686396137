.forgot-password-content-block {
  padding-top: 3rem;
  padding-left: 2.75rem;
  text-align: center;
}

.forgot-password-logo {
  height: 2.8rem;
  width: auto;
  margin-bottom: 1.25rem;
}

.forgot-password-username-block {
  text-align: left;
  padding-top: 15px;
}
