.remote-signature-auth-electronic-signature-disclosure {
  text-decoration: underline;
}

.remote-signature-auth-bold {
  text-align: justify;
  font-weight: bold;
}

.remote-signature-auth-italic {
  text-align: justify;
  font-style: italic;
}

.remote-signature-auth-disclosure-btn {
  margin-right: 1.5em;
  margin-bottom: 1.5em;
  padding-right: 0.5em;
  padding-left: 0.5em;
}

.remote-signature-dowload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
}
