.print-components-padding {
  margin-top: 1rem;
  padding-bottom: 3rem;
}

.print-left-buttons-container {
  text-align: left;
  position: relative;
  float: left;
  width: 50%;
}

.print-right-buttons-container {
  text-align: right;
  position: relative;
  float: left;
  width: 50%;
}

/* Styles for Email Encrypted PDF Mod*/

.email-pdf-mod-input{
  width: 15.6rem;
  height: 2.5rem;
  padding-left: 15px;
}

.email-pdf-mod-div-space{
  border: none;
  margin-top: 0vh;
  margin-bottom: 0vh;
}

.email-pdf-mod-div-1{
  padding-top: 1vh;
  padding-bottom: 0vh;
  padding-left: 1vw;
  display: inline-block;
}
.email-pdf-mod-div-2{
  float: none;
  vertical-align: middle;
  margin-left: 1vw;
  margin-bottom: 1vh;
}

.email-pdf-mod-checkbox{
  width: 17;
  height: 16;
  margin-right: 0.3vw;
  background-color: #F3F9FF;
}

.email-pdf-mod-div-3{
  padding-top: 0vh;
  padding-bottom: 0vh;
  padding-left: 1vw;
  display: inline-block;
}

