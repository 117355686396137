.header-section {
  height: auto;
  display: flex;
  margin-bottom: 1rem;
}

.button-header-section {
  display: inline-flex;
}

.scanner-header-text {
  display: inline-flex;
}

.scanner-header-text p {
  margin: auto 0;
}

.image-content {
  width: 100%;
  border: 1px solid black;
  height: calc(100% - 7.5rem);
  z-index: -1;
  overflow: auto;
  background-color: white;
}

.footer-section {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 1rem;
}

.input-footer-section {
  align-self: flex-start;
  display: inline-flex;
  width: 50%;
}

.button-footer-section {
  display: inline-flex;
  align-self: flex-end;
  margin: auto 0;
}

.footer-document-name {
  display: flex;
  width: 100%;
}