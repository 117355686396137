.table td.forms-link-col {
  border-top: none;
  padding: 0 0 1rem 0.5rem;
}

.table td.forms-link-desc-col {
  color: #8a96a0;
}

.boldText {
  font-weight: 500;
}

.subText {
  margin-left: 0.3em;
  color: #67727c;
}

.subTitle {
  margin-right: 0.5em;
  margin-top: 0.8em;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
}

.subBody {
  margin-right: 1em;
  margin-top: 0.7em;
}
