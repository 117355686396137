.spinner-container {
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
}

.spinner-content {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6rem 0 0 -5rem;
}
