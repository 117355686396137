.preview-doc-archive-modal-container {
  padding: 1rem 1.5rem 0 1.5rem;
}
.preview-doc-archive-modal-title {
  color: white;
  background-color: #444a59;
  height: 4.5rem;
  padding: 1rem 1.5rem 0 1.5rem;
}
.preview-doc-archive-modal-container-table-td-a {
  text-decoration: none;
  box-shadow: none;
  border: none;
}
.preview-doc-archive-modal-container-table-td-a-img {
  display: inline-block;
  vertical-align: -5px;
  height: 20px;
  width: 20px;
  margin-top: 5px;
  padding-left: 5px;
}
.preview-doc-archive-modal-container-table-td-a-btn {
  background-color: #ffffff;
  color: #0077ff;
  border: none;
  font-family: 'Roboto';
  font-size: 15px;
}
.doc-archive-table-div-hr {
  border: 1px solid #ebedf8;
  width: 100%;
  padding-left: 0;
  margin: 2.5vh 0 0 0.75vh;
}
.archive-header-btn-container {
  float: right;
  display: flex;
  justify-content: flex-end;
}

.doc-archive-table-list-status-icon {
  height: 1.5rem;
}

.doc-archive-rmd-list-status-icon {
  width: fit-content;
  padding: 0.2rem 0.7rem 0.2rem 0.5rem;
  border-radius: 0.7rem;
}
.remote-doc-status.expired {
  background-color: #ffe0e0;
}
.remote-doc-status.pending {
  background-color: #ffeebb;
}
.remote-doc-status.completed {
  background-color: #dcf7e7;
}
