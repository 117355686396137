.searchBarResult {
  cursor: pointer;
  padding-top: 0.25rem;
}

.searchBarResult:hover {
  background-color: #dbebfe;
  color: #0077ff;
}

.searchBarContainer {
  padding: 10px;
}

.searchBarResultList {
  list-style: none;
  padding-left: 1rem;
  padding-top: 1rem;
}

.searchBarResultListContainer {
  overflow: hidden;
  background: #d5d5d5;
  width: 100%;
  max-height: 10rem;
  overflow: auto;
  background-color: white;
  border-bottom: 1px solid #d5d5d5;
  border-right: 1px solid #d5d5d5;
  border-left: 1px solid #d5d5d5;
}

.searchBarResultItem {
  color: #0077ff;
  margin-right: 2rem;
}
