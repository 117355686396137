.pprChargesContainer {
  text-align: left;
  margin: 2rem 0;
}

.pprChargesCollapseIconContainer {
  float: left;
}

.pprChargesCollapseHeader {
  font-size: 1.25rem;
  letter-spacing: 0.14px;
  color: #1a173b;
  margin-left: 0.5rem;
}

.pprChargesTableContainer {
  margin: 0 5rem;
  width: 50%;
}
