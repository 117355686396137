.ck p {
  margin-bottom: 0;
}

.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners {
  border: 1px solid var(--ck-color-toolbar-border);
  border-top: 0;
}

.client-letter-editor-title {
  font-family: Roboto;
  font-size: 18px;
  letter-spacing: 0.14px;
  color: #1a173b;
  margin-left: 0.3em;
}

.client-letter-editor-save-btn-container {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}
