.setup-toolbar-acct-logo {
  margin-right: 0.5rem;
  margin-left: 24px;
  height: 30px;
  width: 30px;
}

.setup-toolbar-help-nav-wrapper {
  margin-right: 24px;
}

.setup-toolbar-help-nav-logo {
  height: 25px;
  width: 25px;
}

.setup-toolbar-help-nav-label {
  padding-top: 5px;
  color: #0077ff;
}

.setup-lock-icon {
  position: relative;
  padding-right: 0.5em;
  margin-left: 5px;
  margin-bottom: 5px;
  height: 20px;
}

.setup-nav-btns {
  margin-top: 1rem;
  padding-bottom: 1rem;
  text-align: right;
  width: 100%;
}

.setup-nav-btns button {
  color: #0077ff;
  text-transform: capitalize;
  font-family: Roboto;
  width: 10vw;
  margin-right: 1vw;
}

.setup-section-heading {
  text-align: left;
  margin-bottom: 2vh;
  font-family: Roboto;
  font-size: 21px;
  font-weight: 400;
  letter-spacing: 0.14px;
  color: #1a173b;
}

.header-bar-help-block {
  display: flex;
}