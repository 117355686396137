.help-menu-block {
  height: 100%;
  width: 290px;
  padding: 1rem 0;
  background-color: #ffffff;
}

.div-style {
  margin-left: 31px;
}

.div-style-bottom {
  margin-left: 31px;
  border-bottom: 1px solid #ebedf8;
  padding-bottom: 0.5rem;
  margin-right: 31px;
}

.help-menu-last-block {
  border: none;
}

.replace-hr {
  margin-top: 2vh;
  margin-bottom: 1vh;
  border-bottom: 1px solid #808080;
  padding-bottom: 1rem;
}

[class*='link-text'] {
  height: 20px;
  opacity: 0.75;
  color: #0077ff;
  font-family: 'Roboto';
  font-size: 15px;
  letter-spacing: 0.1px;
  line-height: 20px;
  margin-bottom: 8px;
}

[class*='link-text-bottom'] {
  height: 20px;
  opacity: 0.75;
  color: #0077ff;
  font-family: 'Roboto';
  font-size: 15px;
  letter-spacing: 0.1px;
  line-height: 20px;
  margin-bottom: 8px;
  border-bottom: 1px solid #808080;
  padding-bottom: 1rem;
}

[class*='link-text-top'] {
  height: 20px;
  opacity: 0.75;
  color: #0077ff;
  font-family: 'Roboto';
  font-size: 15px;
  letter-spacing: 0.1px;
  line-height: 20px;
  margin-bottom: 8px;
  margin-top: 5px;
}
