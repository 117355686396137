.ovContainer {
  max-width: 1280px;
  margin: 0 auto;
}

.ovLabel {
  height: 29px;
  color: #354052;
  font-size: 21px;
  letter-spacing: 0.14px;
  line-height: 24px;
  padding: 0em 1em 0em 0em;
  margin-bottom: 1em;
}

.ovLabelNew {
  height: 29px;
  color: #354052;
  font-size: 21px;
  letter-spacing: 0.14px;
  line-height: 24px;
  padding: 0em 1em 0em 0em;
}

span {
  user-select: none;
}

.ovTopRow {
  padding-top: 1em;
}

.ovButton {
  border-radius: 4px;
  background: #0077ff;
  color: white;
  font-size: 14px;
  line-height: 16px;
  max-width: 9em;
  height: 35px;
  outline: 0px !important;
}

.ovNotebook {
  background-color: white;
  border: 1px solid #ebedf8;
  box-shadow: none !important;
  margin-top: 0em;
  margin-bottom: 22px;
  padding: 3rem;
}

/* iPad view of the Tax Return List  */
@media only screen and (max-width: 1025px) {
  .ovNotebookTaxRets {
    overflow-x: scroll;
    width: 850px;
  }
}

.ovNotebookTwo {
  background-color: #fafbfc;
  border: 1px solid #fafbfc;
  border-top: none;
  padding-bottom: 1em;
  padding-top: 1em;
  margin-top: 0em;
}

.ovNotebookTab {
  border-radius: 0 3px 0 0;
  border: 1px solid #ebedf8;
  background-color: #fafbfc;
  padding-bottom: 25px;
  padding-top: 20px;
  min-height: 26vh;
}

.ovNotebookTab-focus {
  background-color: white;
  border-bottom: none;
}

.ovTabHeader {
  color: #354052;
  font-size: 15px;
  font-weight: 550;
  line-height: 20px;
  margin-bottom: 3em;
  /* position: relative;
    top: 23px;
    left: -14px; */
}

.ovTabBigNumber {
  color: #67727c;
  font-size: 27px;
  line-height: 37px;
  text-align: center;
  padding-left: 0.5em;
}

.ovTabBigNumberLabel {
  width: 65px;
  color: #67727c;
  letter-spacing: 0.1px;
  line-height: 29px;
  padding: 0em 0em 0em 3em;
  text-overflow: unset;
  white-space: nowrap;
  position: relative;
  top: -2.77px;
}

.ovTabAlertData {
  padding-top: 1.5em;
  display: block;
}

.ovOnHover {
  cursor: pointer;
  transition: 0.5s;
}

.ovOnHover:hover {
  background-color: #f3f4f9;
}

.ovAlertTitle {
  height: 16px;
  width: 191px;
  color: #0077ff;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ovAlertTime {
  height: 16px;
  width: 57px;
  color: #000000;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 16px;
  position: relative;
  top: -0.5em;
}

.previewTable {
  width: 100%;
}

.ovYearDropdown {
  color: #ff0000;
}

.ovNotifCalText {
  height: 49px;
  width: 152px;
  opacity: 0.2;
  color: #1a173b;
  font-size: 36px;
  font-weight: 500;
  letter-spacing: 0.14px;
  line-height: 49px;
  position: relative;
  left: 13em;
  top: 3em;
}

.ovFooter {
  height: 21px;
  width: auto;
  color: #aab2c0;
  font-size: 15.2px;
  font-weight: 500;
  line-height: 21px;
}

.arrow-down-addnew {
  border-top: 5px solid white;
  position: relative;
  top: 13px;
  left: 8px;
}

.ovTableTitle {
  padding-left: 0;
  font-size: 20px;
  font-weight: normal;
}

.ovRowHeader {
  color: #354052;
  font-size: 12px;
  letter-spacing: 1px;
  padding-right: 1em;
  text-transform: uppercase;
}

.rejectInfoBubble {
  vertical-align: bottom !important;
  padding: 0 !important;
}

.ovRowData {
  color: #354052;
  font-size: 15px;
  padding-bottom: 1em;
}

.ovPreparerRowData {
  color: #354052;
  font-size: 15px;
  margin-top: 2em;
  vertical-align: bottom !important;
}

.ovPreparerProgressBar {
  width: 37em;
  margin: 0;
  padding: 0;
}

.ovPreparerName {
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 0;
  margin-bottom: 0;
}

.ovPreparerImage {
  margin-right: 1em;
  width: '65px';
  height: '65px';
}

.ovListPaper {
  overflow: auto;
  border-radius: 5px !important;
}

.msg-alert {
  margin-bottom: 1rem;
  padding: 1rem;
  position: relative;
}

.msg-alert-label {
  padding-right: 1rem;
}
