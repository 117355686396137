.text-container {
  margin-top: 2.5rem;
}

.text-form {
  margin-left: 3rem;
  margin-right: 3rem;
  margin-top: 1rem;
}

.text-title-container {
  text-align: left;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.text-title-container-content {
  font-family: Roboto;
  font-size: 21px;
  letter-spacing: 0.14px;
  color: #1a173b;
}

.text-title-container-has-textlink {
  float: right;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0.14px;
  color: #1a173b;
}

.text-body-container {
  margin-left: 1.5rem;
  color: #354052;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.text-note-container {
  margin-left: 1.5rem;
  color: #354052;
  font-family: Roboto;
  font-size: 14px;
}

.text-form-control {
  margin-left: 2rem;
  float: left;
}

.text-form-control-checkbox {
  width: 17px;
  height: 16px;
}

.text-form-control-checkbox-outline {
  font-size: 24px;
  color: #8fc3ff;
}

.text-form-control-checkbox-checked {
  font-size: 24px;
}
