.message-container {
  border: 1px solid #ebedf8;
  border-radius: 3.8px;
  background-color: #ffffff;
  padding: 30px;
  min-height: 700px;
}

.message-container.sm-min-height {
  min-height: 427px;
}

.message-content-row {
  align-items: center;
  margin-bottom: 1rem;
}

.message-none {
  text-align: center;
  margin-top: 7em;
  margin-right: 18em;
}

.message-heading {
  padding-left: 0px;
}

.message-small-message-text {
  color: #67727c;
  font-size: 12px;
}

.message-Title {
  color: #1a173b;
  font-family: Roboto;
  font-size: 21px;
  letter-spacing: 0.14px;
  line-height: 24px;
  margin: 12px 0;
}

.message-sender {
  color: #0077ff;
  font-size: 12px;
}

.message-body {
  border-top: 3px solid #ebedf8;
  padding: 1rem 0 0;
  color: #354052;
}

.message-text-linkLink {
  cursor: pointer;
  color: #0077ff;
}
