.access-level-container {
  flex-grow: 1;
  padding: 25px;
  height: 60%;
  overflow-y: auto;
}
.access-level-sub-header {
  height: 20%;
  padding-left: 25px;
  padding-top: 10px;
}
.access-level-footer {
  height: 10%;
}
#btnAddNewAccessRole {
  margin-left: auto;
}
