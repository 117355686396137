.setup-content-container {
  margin: 1rem 0 0;
}

.setup-sidebar-dot-icon {
  background-color: #f0a6a9;
  height: 8px;
  width: 8px;
  margin-right: 10px;
  margin-top: 5px;
  border-radius: 4px;
  float: left;
  vertical-align: center;
}

.sidebar-checkmark-img {
  height: 1rem;
  width: 1rem;
}

.tech-support-number-content {
  margin-left: 2rem;
  margin-bottom: 1rem;
}
