.preparer-setup-container {
  margin-top: 2.5rem;
}

.row-padding-bottom {
  font-family: Roboto;
  font-size: 15px;
  color: #67727c;
  padding-bottom: 0.5vh;
  padding-top: 1vh;
}

.preparer-setup-form {
  margin-left: 3vw;
  margin-right: 3vw;
  margin-top: 1vh;
}

.preparer-setup-form-content {
  text-align: left;
  padding-top: 3vh;
  padding-bottom: 1vh;
}

.preparer-setup-form-heading {
  font-family: Roboto;
  font-size: 21px;
  letter-spacing: 0.14px;
  color: #1a173b;
  float: left;
}

.preparer-setup-form-btn-container {
  text-align: right;
  width: 100%;
  padding: 0;
  margin-left: 0vw;
  margin-right: 3vw;
}

.preparer-setup-table {
  margin-top: 25px;
  margin-left: 2vw;
  width: 95%;
}

.preparer-setup-table-heading {
  font-family: Roboto;
  font-size: 12px;
  color: #1a173b;
  width: 20%;
  padding-bottom: 0%;
}

.heading-30-perc-width {
  width: 30%;
}

.preparer-setup-paginate-preparer {
  width: 100%;
  padding: 0;
  margin-left: 0vw;
  margin-right: 3vw;
}

.preparer-setup-divider-top-margin {
  margin-top: 1.5rem;
}

.preparer-setup-divider-left-right-margin {
  margin-left: 1.5vw;
  margin-right: 1.5vw;
}
