.wizard-side-bar-forms-content {
  height: 93%;
  min-width: 17.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
}

.wizard-form-nav-container {
  margin: 0 0 0 0.5rem;
  overflow-x: auto;
  border-top: 1px solid #ddd;
  overflow-y: auto;
  padding: 1em 0;
}

.wizard-sidebar-form-nav.adjusted-height-18rem {
  height: calc(100% - 18.5rem);
}

.wizard-sidebar-form-nav.adjusted-height-17rem {
  height: calc(100% - 17rem);
}

.wizard-sidebar-form-nav.adjusted-height-16rem {
  height: calc(100% - 16.75rem);
}

.wizard-sidebar-form-nav.adjusted-height-15rem {
  height: calc(100% - 15.5rem);
}

.wizard-sidebar-form-nav.adjusted-height-14rem {
  height: calc(100% - 14.5rem);
}

.wizard-fed-refund-amt {
  padding: 0.5rem 1rem;
  margin-left: 1em;
}

.wizard-add-a-form-btn-container {
  margin-top: auto;
  background-color: #f1f3f5;
  bottom: 0;
  text-align: center;
  width: 100%;
  padding: 0.75rem 0;
  border-top: 1px solid #ddd;
  position: relative;
}
.wizard-menulist-menuitem-dot {
  height: 8px;
  width: 8px;
  background-color: #f0a6a9;
  border-radius: 50%;
  display: inline-block;
  margin: 0 0.5rem 0.1rem 0;
}
.wizard-menulist-menuitem-icon {
  height: 1.2rem;
  width: 1.2rem;
  margin-right: 4.8px;
}

.wizard-menulist-menuitem-icon-hidden {
  height: 1.2rem;
  width: 1.2rem;
  margin-right: 4.8px;
  visibility: hidden;
}
.wizard-sidebar-form-nav-header {
  height: auto;
  margin: 1rem 0 1rem 3rem;
}
.wizard-sidebar-form-nav-header-text {
  color: #1a173b;
  font-family: Roboto;
  font-size: 21px;
  letter-spacing: 0.14px;
  line-height: 24px;
  margin: 0;
}
.wizard-form-cell-item {
  width: 2.1rem;
}
.wizard-add-form-btn {
  width: 12rem;
}

.wizard-collapse-icon {
  color: 'lightgrey',
}
