.restricted-fields-page-container {
  margin-top: 2.5rem;
  margin-left: 3vw;
}

.restricted-fields-paper {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.restricted-fields-heading {
  font-family: 'Roboto';
  font-size: 21px;
  letter-spacing: 0.14px;
  color: #1a163b;
  margin-left: 3vw;
  margin-right: 3vw;
  margin-top: 1vh;
}

.restricted-fields-table-container {
  margin: 0 5rem;
  width: 60%;
}

.restricted-fields-table-header-divider {
  margin-bottom: 1vh;
}

.restricted-fields-table-cell {
  width: 12%;
  color: #1a173b;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 'normal';
  letter-spacing: '1px';
  border-bottom: 'none';
}

.restricted-fields-info-button {
  border: 'none';
  outline: 'none';
}
