.fab {
  position: fixed;
  z-index: 9;
}

.fab-support {
  bottom: 5rem;
  right: 0;
  background-color: #007bff;
  box-shadow: -1px 2px 2px -1px #000;
  padding: 1rem 0;
}

.fab-support-icon {
  color: #fff;
  font-size: 2.5rem !important;
}

.fab-support-mask {
  z-index: 999999;
  width: 3.125rem;
  height: 10.625rem;
  opacity: 0;
}
