.row {
  margin-right: 30px;
  margin-left: 30px;
  margin-top: 10px;
}

/* iPad view of the Tax Return List  */
@media only screen and (max-width: 1025px) {
  .row {
    margin-right: 30px;
    margin-left: 0px;
    margin-top: 10px;
  }
}

.control-btn {
  font-family: Arial;
  color: #ffffff;
  font-size: 12px;
  background: #a5a7a8;
  padding: 10px 20px 10px 20px;
  border: solid #000000 2px;
  text-decoration: none;
}

.control-btn:hover {
  background: #a5a7a8;
  background-image: -webkit-linear-gradient(top, #a5a7a8, #6f7375);
  background-image: -moz-linear-gradient(top, #a5a7a8, #6f7375);
  background-image: -ms-linear-gradient(top, #a5a7a8, #6f7375);
  background-image: -o-linear-gradient(top, #a5a7a8, #6f7375);
  background-image: linear-gradient(to bottom, #a5a7a8, #6f7375);
  text-decoration: none;
}

.control-btn:disabled {
  font-family: Arial;
  color: lightgray;
  font-size: 12px;
  background: #a5a7a8;
  padding: 10px 20px 10px 20px;
  border: solid #000000 2px;
  text-decoration: none;
}
